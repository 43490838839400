.clyde-modal {
  position: fixed;
  bottom: -200vh;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  transition: bottom 300ms ease;
  &.active {
    bottom: 0;
    transition: bottom 300ms ease;
  }
}

.clyde-modal__background {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms ease;
  &.active {
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 300ms ease;
  }
}

.clyde-modal--full {
  padding: 0;
  .clyde-modal__top {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    top: 0;
  }
  .clyde-modal__bottom{
    display: none;
  }
}

.clyde-modal__top {
  position: absolute;
  background-color: $clydeWhite;
  bottom: 80px;
  height: 80%;
  width: 90%;
  border-radius: 10px 10px 0 0;
  margin: 0 5%;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.clyde-modal__close {
  position: absolute;
  right: 3vw;
  top: 3vh;
  color: $clydeBlack;
  background-color: $clydeLightestGrey;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  font-size: 35px;
  font-family: 'Volte';
  padding-right: 7px;
  padding-top: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.claim-modal.active .clyde-modal__close {
  position: fixed;
}

.clyde-modal__content {
  width: 100%;
  padding-top: 40px;
}

.clyde-modal__bottom {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 80px;
  background-color: $backgroundBlack;
}

@include respond-to(md) {
  .claim-modal.active .clyde-modal__close {
    position: absolute;
  }
}

@include respond-to(xl) {
  .clyde-modal__top {
    width: 80%;
    margin: 0 10%;
  }
}
