.terms--modal {
  padding-left: 10%;
  padding-right: 10%;
  background: linear-gradient(to bottom, white 0%, white 250px, #F6F6F6 250px, #F6F6F6 100%);
}

.contract-terms-page {
  width: 90%;
}

.contract-terms-page--404 {
  height: calc(100vh - 460px);
  width: 90%;
}

.contract-terms-page--download-btn-container {
  width: 100%;
  display: block;
  justify-content: center;
}

.terms-wrapper {
  color: $clydeBlack;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terms-wrapper strong {
  font-family: Volte Medium;
}

.terms-wrapper__copyright {
  margin: 15px 0 0 auto;
}

.terms-summary-tiles {
  margin-bottom: 40px;
}

.terms-summary-tile {
  height: 240px;
  padding: 20px;
  border-radius: 5px;
  background-color: $clydeWhite;
  box-shadow: 0 10px 20px -5px rgba(0,0,0,.25);
  margin-bottom: 15px;
}

.terms-summary-tile__image {
  margin-bottom: 15px;
  svg {
    height: 40px;
    color: $clydeOrange;
  }
}

.terms-summary-tile__title {
  margin-bottom: 5px;
  h5 {
    font-size: 18px;
    margin-bottom: 0;
  }
}

.terms-summary-tile__text {
  font-size: 14px;
}

.terms-header {
  font-size: 44px;
  font-family: 'Volte Medium';
  margin-bottom: 10px;
}

.terms-sub-header {
  font-size: 16px;
  font-family: $boldFontFamily;
  color: $clydeOrange;
  margin-bottom: 20px;
}

.terms-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  background-color: $clydeWhite;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: none;
  transition: all .2s ease;
}

.terms-section:hover {
  box-shadow: 0 10px 20px -5px rgba(0,0,0,.25);
  -webkit-transition: box-shadow .2s ease;
  transition: box-shadow .2s ease;
}

.terms-section-toggle {
  width: 90%;
  padding-top: 25px;
  padding-bottom: 25px;
  cursor: pointer;
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.terms-section-header {
  display: inline;
  margin-top: 0;
  margin-bottom: 0;
  color: $clydeText;
  font-size: 1.5em;
  letter-spacing: 0;
  font-family: Volte;
}

.terms-section-arrow {
  width: 16px;
  min-width: 16px;
  transition: transform .2s ease;
}

.terms-section-arrow--open {
  transform: rotateZ(180deg);
}

.terms-section-arrow:before {
  transform: rotateZ(-90deg);
}

.terms-section-static {
  width: 100%;
  font-size: 1em;
  margin-bottom: 20px;
}

.terms-section-static p:last-child {
  margin-bottom: 0;
}

.terms-section-collapse {
  width: 90%;
  font-size: 1em;
}

.terms-section-content {
  padding-bottom: 25px;
}

.terms-section-content p:last-child {
  margin-bottom: 0;
}

.terms-section-content--fixed p {
  display: block;
}

.contractname {
  font-family: Volte Bold;
  font-size: 50px;
  margin: 20px 0px 15px 0px;
}

.contract-details {
  font-size: 14px;
}

.contract-terms-page-download-bar {
  height: 80px;
  min-height: 80px;
  width: 100vw;
  max-width: 100%;
  background-color: $backgroundBlack;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contract-terms-page--download-btn {
  min-width: 158px;
  height: 50px;

  .button--general {
    font-size: 16px;
    padding: 12px 24px;
  }
}

.contract-terms-page-download-bar__button {
  height: 40px;
  padding: 0 40px 0 40px !important;
}

contract-terms-page--download-btn,
.contract-terms-page-download-bar__button:hover {
  color: $clydeWhite !important;
  background-color: $clydeGreen;
}

.m-b-none {
  margin-bottom: 0px;
}

@include respond-to(sm) {
  .terms--modal {
    background: linear-gradient(to bottom, white 0%, white 230px, #F6F6F6 230px, #F6F6F6 100%);
  }

  .terms-summary-tile {
    height: 210px;
  }
}

@include respond-to(md) {
  .contract-terms-page--download-btn-container {
    justify-content: right;
  }

  .terms--modal {
    .terms-summary-tile {
      height: 210px;
    }
  }

  .terms--static {
    .terms-summary-tile {
      height: 210px;
    }
  }

  .terms-summary-tiles {
    margin-bottom: 24px;
    justify-content: center;
  }

  .terms-summary-tile__image {
    margin-bottom: 25px;
  }

  .terms-summary-tile__title {
    margin-bottom: 15px;
  }

  .contract-terms-page-download-bar {
    padding: 0 14% 0 0;
    justify-content: flex-end;
    align-items: center;
  }
}

@include respond-to(lg) {
  .contract-terms-page {
    padding: 0 189px;
    width: 100%;
  }

  .terms--modal {
    background: linear-gradient(to bottom, white 0%, white 275px, #F6F6F6 275px, #F6F6F6 100%);
    .terms-summary-tile {
      height: 200px;
    }
  }

  .terms--static {
    .terms-summary-tile {
      height: 320px;
      margin-bottom: 0;
    }
  }
}

@include respond-to(xl) {
  .terms--modal {
    .terms-summary-tile {
      height: 310px;
    }
  }

  .terms--static {
    .terms-summary-tile {
      height: 290px;
    }
  }
}

@media (min-width:1440px) {
  .terms--modal {
    padding-left: 15%;
    padding-right: 15%;
  }
}

ol {
  padding-left: 20px;
}

.contract-terms-accordion-table {
  border: solid 1px black;

  & thead th {
    padding: 5px;
  }

  & tbody td {
    padding: 5px;
    border: 1px solid black;
  }
  & tbody th {
    padding: 5px;
    border: 1px solid black;
  }
}
