.resolution-alert-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .clyde-modal__top {
    max-width: 900px;
    height: auto;
    bottom: auto;
    border-radius: 8px;
  }

  .clyde-modal__bottom {
    display: none;
  }

  .clyde-modal__content {
    height: max-content;
  }

  .claim-form__response__body {
    margin: 20px 5%;
    padding: 40px 60px 60px;
    background-color: $clydeWhite;
    .claim-form__response__body__icon {
      margin-top: 0;
    }
  }

  .claim-form__response__promo-code-buttons {
    .button--click-copy {
      margin: 0;
      margin-right: 20px;
    }
  }
}

@include respond-to(lg) {
  .resolution-alert-modal {
    .claim-form__response__body {
      margin: 20px auto;
    }
  }
}