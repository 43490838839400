// CLAIM ISSUE STYLES
.claim-form__issues__back {
  position: relative;
  margin-bottom: 24px;
  padding: 16px 50px 16px 20px;
  border: 1px solid $clydeMidGrey;
  border-radius: 4px;
  cursor: pointer;

  h4 {
    margin-bottom: 10px;
    color: $clydeText;
    font-size: 13px;
  }

  .claim-form__issues__back__arrow {
    position: absolute;
    top: calc(50% - 5px);
    right: 30px;
    height: 10px;
    color: $clydeText;
  }
}

.claim-form__step__issue-tagline {
  color: $clydeMidGrey;
}

.claim-form__issue-type__list {
  flex-wrap: wrap;
  display: flex;
}

.claim-form__issue-type__type-item {
  color: $clydeBlack;
  border: 1px solid $clydeMidGrey;
  padding: 16px 20px;
  margin: 0 16px 16px 0;
  border-radius: 4px;
  cursor: pointer;
  height: 50px;

  &:hover {
    border-color: $clydeBlack;
  }
}

.claim-form__issue-type__type-item.selected{
  color: $clydeBlack;
  border: 1px solid $clydeBlack;
  
  &:hover {
    border: 1px solid $clydeBlack;
  }
}

.claim-form__issue-type__info {
  margin-left: 25px;
}

.claim-form__issue-type__title {
  font-family: 'Volte';
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: black;
}