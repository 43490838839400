.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0 5% 225px;
  padding-bottom: 225px;
  z-index: 3;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  min-height: calc(100vh - 200px);
}

.app-underlay {
  position: absolute;
  z-index: -1;
  background-color: $clydeGrey;
  width: 100%;
  height: auto;
  top: 370px;
  bottom: 80px;
}

@keyframes getFunky {
  10% {
    margin-left: -90%;
    width: 10%;
  }

  50% {
    margin-left: -50%;
    width: 50%;
  }

  100% {
    margin-left: 0;
    width: 100%;
  }
}

.user-header__logo {
  width: 70px;
  margin-bottom: 15px;
}

.user-header__login {
  position: absolute;
  left: calc(100vw - 10%);
  top: 18px;
}

.user-header {
  width: 120%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 20px 0 0;
  border-bottom: 1px solid #F6F6F6;
}

.user-nav {
  position: fixed;
  display: flex;
  width: 100%;
  height: 75px;
  bottom: calc(env(safe-area-inset-bottom));
  right: 0;
  left: 0;
  margin: 0;
  padding: 13px 35px 0px;
  justify-content: center;
  background: $clydeWhite;
  font-weight: 500;
  z-index: 5;
  border-top: 1px solid $clydeLightestGrey;
}

.user-nav__nav-item:not(:last-child) {
  margin-left: 0;
  margin-right: 32px;
}

@mixin nav-item-margin {
  margin: 0 10px 0 10px;
}

.link-underline {
  display: none;
  height: 3px;
  width: 100%;
  background: $clydeGradient;
  margin: 4px 0 0 0;
}

.user-nav__nav-item {
  font-family: Volte;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  color: $clydeText;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 5px;
  }

  &.active {
    color: $clydeText;

    .link-underline {
      margin-top: auto;
      display: block;
    }
  }

  &:hover {
    text-decoration: none;
    color: $clydeText;
  }
}

.user-nav__nav-item__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 11px;
  height: 58px;

  svg {
    margin-bottom: 5px;
  }

  &--active {
    color: $clydeBlack;
  }

  &--inactive {
    color: $clydeMediumGrey
  }
}

.user-nav__nav-item__logout {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  color: $clydeMediumGrey;
  text-transform: capitalize;
  font-size: 11px;
  line-height: 16px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    color: $clydeText;
  }
}

.overflow-off {
  overflow: hidden;
}

.clyde-footer {
  display: flex;
  flex-direction: column;
  color: $clydeWhite;
  background-color: $backgroundBlack;
  padding: 40px;
  position: relative;
  z-index: 2;
  bottom: 75px;
  gap: 32px;

  a,
  div {
    color: $clydeWhite;
    font-family: 'Volte Light';
  }

  a:hover {
    color: $clydeWhite;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

.clyde-footer__logo {
  width: 60px;

  .text path {
    fill: #fff;
  }
}

.clyde-footer__content {
  display: flex;
  flex-direction: column-reverse;
  gap: 32px;
}

.clyde-footer__content__copyright {
  font-size: 13px;
  display: none;
}

.clyde-footer__content__links {
  display: flex;
  flex-direction: column;
  gap: 2px;
  line-height: 1.5;
}

.user-nav--logged-out {
  display: none;
}

@include respond-to(sm) {
  .app-container {
    padding: 0 46px 150px;
  }

  .clyde-footer {
    bottom: 0;
  }

  .clyde-footer__content__copyright {
    display: block;
    font-size: 15px;
  }

  .app-underlay {
    max-width: 100%;
    bottom: 0;
  }

  .user-header {
    flex-direction: row;
    padding: 20px 0 40px;
    width: 100%;
    border-bottom: 0;

    svg {
      width: 60px;
    }
  }

  .user-header__logo {
    margin-bottom: 0;
  }

  .user-nav {
    position: absolute;
    display: flex;
    width: 300px;
    top: 35px;
    right: 0;
    left: auto;
    bottom: auto;
    padding: 0;
    z-index: 0;
    background-color: $backgroundGrey;
  }

  .user-nav__nav-item {
    @include nav-item-margin;

    svg {
      display: none;
    }

    &.active {
      color: $clydeBlack;

      .link-underline {
        display: block;
        margin-top: 4px;
        height: 6px;
        width: 50%;
        border-radius: 10px;
      }
    }
  }

  .user-nav__nav-item__link {
    height: auto;
  }

  .user-nav--logged-out {
    display: flex;
    justify-content: flex-end;

    .user-nav__nav-item {
      height: 20px;

      &:last-of-type {
        margin-left: 0;

        .user-nav__nav-item__link {
          margin-left: 24px;
        }
      }

      &:not(:last-of-type) {
        margin-right: 0;

        .user-nav__nav-item__link {
          padding-right: 24px;
          border-right: 2px solid $clydeBlack;
        }
      }
    }
  }

  .user-nav__nav-item,
  .user-nav__nav-item__logout {
    margin: 0 7px 0 7px;
  }

  .user-header__login {
    display: none;
  }

  .user-nav__nav-item__link {
    font-size: 15px;
  }
}

@include respond-to(md) {
  .user-nav {
    margin: 0 30px;
    border: none;
  }

  .user-nav--logged-in {
    justify-content: space-between;
  }

  .user-header__login {
    display: none;
  }

  .user-nav__nav-item__logout {
    font-size: 15px;
  }

  .user-nav .user-nav__nav-item {
    @include nav-item-margin;
  }

  .clyde-footer {
    padding: 57px 220px;
  }

  .clyde-footer__content__copyright {
    display: block;
  }
}

@include respond-to(lg) {
  .clyde-footer__content {
    flex-direction: row;
    flex-wrap: wrap-reverse;
  }

  .clyde-footer__content__copyright {
    flex-grow: 1;
  }

  .clyde-footer__content__links {
    flex-direction: row;
    justify-content: flex-end;
    gap: 32px;
  }
}
