.pdp-detail__details__info-main-container {
  .pdp-detail__expires-date,
  .pdp-detail__details__right {
    position: absolute;
    right: 20px;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.pdp-detail__expires-button,
.pdp-detail__expires-button:hover {
  display: none;
  margin: 0 0 0.5rem;
  padding: 0;
  background: none;
  border: 0;
  color: $clydeMutedGrey;
  font-size: 15px;
  text-decoration: underline;
}

.pdp-detail__expires-date {
  height: 90px;
  width: 90px;
  margin-top: -25px;
  margin-bottom: 20px !important;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;

  .pdp-detail__expires-date__bottom {
    width: 100%;
    padding: 8px 0;
    flex: 1;
    border: 1px solid $clydeMidGrey;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    justify-content: center;
    font-family: Volte Medium;
  }

  .pdp-detail__expires-date__top {
    width: 100%;
    margin-bottom: 0;
    padding: 6px;
    justify-content: center;
    background: $backgroundBlack;
    color: $clydeWhite;
    border-radius: 6px 6px 0 0;
    font-size: 12px;
  }

  &--hidden {
    opacity: 0;
  }
}

.contract-dates-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#000000, .7);

  .clyde-modal__bottom {
    display: none;
  }

  .clyde-modal__top {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
    bottom: auto;
  }

  .clyde-modal__content {
    padding: 45px 20px;
  }

  .clyde-modal__close {
    top: 30px;
    right: 30px;
    justify-content: flex-end;
  }
}

.contract-dates-modal__container {
  display: flex;
  height: 100%;
  flex-direction: column;

  h3 {
    width: 100%;
  }

  .contract-dates-modal__help {
    display: flex;
    margin-top: 40px;
    margin-bottom: 0;

    a {
      color: inherit;
      text-decoration: underline;
    }

    svg {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 8px;
    }
  }
}

.contract-dates-modal__date-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  padding: 0;
  margin: 40px 0 0;
  list-style-type: none;

  .date-list__item {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 28px;
    align-items: center;
    flex-wrap: wrap;
    color: $clydeText;

    .date-list__item__date {
      margin-left: 35px;
      margin-right: 3px;
      font-family: Volte Semibold;
    }

    .date-list__item--effective__term--desktop-only {
      display: none;
    }

    .date-list__item--effective__term--mobile-only {
      position: absolute;
      top: 20px;
      width: 100%;
      margin-left: 36px;
    }

    .date-list__item__dot {
      position: absolute;
      display: inline-block;
      height: 25px;
      width: 25px;
      z-index: 5;
      box-sizing: border-box;
      background: $clydeText;
      border: 4px solid $clydeWhite;
      border-radius: 50%;
    }

    .date-list__item__line {
      position: absolute;
      height: 50px;
      left: 12px;
      top: 10px;
      z-index: 1;
      width: 1px;
      border-left: 1px solid $clydeText;
      z-index: 0;

      &--progress {
        display: none;
      }
    }

    &--effective {
      color: $clydeText;
  
      .date-list__item__dot {
        background: $clydeText;
      }
    
      .date-list__item__line {
        display: none;
      }

      .date-list__item__effective-info {
        position: absolute;
        top: calc(100% + 30px);
        left: 35px;
        color: $clydeMediumGrey;
        font-size: 11px;

        .date-list__item__effective-info__more {
          display: block;
          margin-top: 10px;
        }

        .date-list__item__effective-info__button {
          cursor: pointer;
          outline: none;

          svg {
            margin-right: 8px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &--expires {
      flex: 1;
      margin-bottom: 0;
      color: $clydeMidGrey;
      align-items: flex-end;

      .date-list__item__dot {
        background: $clydeMidGrey;
      }
    
      .date-list__item__line {
        border-color: $clydeMidGrey;
        top: -27px;
        height: auto;
        bottom: 25px;

        &--progress {
          display: block;
          top: 0;
          left: -1px;
          border-color: $clydeText;
        }
      }
    }
  }
}

// media queries
@include respond-to(sm) {
  .contract-dates-modal {
    .clyde-modal__content {
      padding: 45px;
    }
  }

  .contract-dates-modal__date-list .date-list__item {
    .date-list__item--effective__term--desktop-only {
      display: inline-block;
    }

    .date-list__item--effective__term--mobile-only {
      display: none;
    }

    .date-list__item__date {
      margin-left: 45px;
    }

    .date-list__item__effective-info {
      top: calc(100% + 10px);
      left: 45px;
    }
  }
}

@include respond-to(md) {
  .contract-dates-modal__date-list {
    height: auto;
    flex: 1;
  }

  .contract-dates-modal {
    .clyde-modal__top {
      height: 70%;
      width: 60%;
      margin: 0 5%;
      min-width: 485px;
      border-radius: 10px;
    }
  }
}

@include respond-to(lg) {
  .pdp-detail__details__info-main-container {
    .pdp-detail__expires-date,
    .pdp-detail__details__right {
      display: flex;
      position: relative;
      right: auto;
      top: auto;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  
  .pdp-detail__expires-button,
  .pdp-detail__expires-button:hover {
    display: block;
  }
}