.legal-container {
  width: 100%;
}

.legal-container h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.legal-background {
  padding: 32px;
  border-radius: 10px;
  background-color: #ebebeb;
  color: #000000;
}

.legal-background strong {
  font-family: Volte Semibold;
}

.legal-background p {
  font-size: 1em;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .legal-background p {
    font-size: 1.1em;
  }
}

@media screen and (min-width: 992px) {
  .legal-container {
    max-width: 1140px;
    padding-left: 10%;
    padding-right: 10%;
  }
}