.claim-history {
  display: flex;
  flex-direction: column;
}

.claim-history__event__list {
  padding: 15px 30px 40px 30px;
}

.claim-history__info__list {
  padding: 30px 30px 20px 30px;
}

.claim-history__event__list__event {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.claim-history__spacer {
  display: static;
  border-left: 1px solid $clydeBlack;
  width: 0px;
  height: 42px;
  margin-left: 20px
}

.claim-history__event__list__event:last-child {
  border-bottom: none;
}

.claim-history__event__list__event--no-history {
  span {
    font-family: Volte Semibold;
  }
}

.claim-history__event__list__event__content {
  display: flex;
  flex-direction: row;
}

.claim-history__event__list__event__content__date {
  font-family: Volte Semibold;
}

.claim-history__info__list {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 10px;
    }
  }
}

.claim-history__info__list__title {
  font-family: Volte Semibold;
}

.claim-history__info__list__content:before {
  content: "\A";
  white-space: pre;
}

.claim-history__info__list__narrative {
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.claim-history__promo-code {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  padding-bottom: 32px;

  &:focus {
    box-shadow: none;
  }
}

.claim-history__click-copy.button--click-copy {
    position: relative;
    height: 50px;
    margin-right: 20px;
    border: 2px solid $clydeText;
    border-radius: 10px;
    color: $clydeText;

    &:focus {
      box-shadow: none;
    }

    .button--click-copy__copy-text {
      font-family: Volte Semibold;
    }

    .button--click-copy__guideline {
      font-size: 14px;
      position: absolute;
      top: calc(100% + 12px);
      left: 0;
    }
}

.claim-history__event__list__event__content {
  flex-direction: row;
  // justify-content: space-between;
}

.claim-history__event__list__event__content__date {
  font-family: Volte Semibold;
  margin: auto 8px;
}

.claim-history__event__list__event__content__status {
  margin: auto 8px;
}

@include respond-to(lg) {
  .history-modal .history-modal__close {
    justify-content: flex-end;
    top: 32px;
    right: 20px;
  }

  .claim-history {
    flex-direction: column;
    justify-content: space-between;
  }

  .claim-history__info {
    margin-bottom: 0;
  }

  .claim-history__event__list__event {
    height: auto;
  }
}

.history-modal .clyde-modal__content {
  margin-top: 10%;
  background-color: $clydeWhite;
  border-radius: 32px 32px 0px 0px;
}

.history-modal .clyde-modal__top {
  background: transparent;
}

.modal-title {
  margin-top: 33px;
}

.history-modal .clyde-modal__content {
  padding: 0px 0px;
}

.claim-history__dot {
  height: 25px;
  width: 25px;
  border: 4px solid $clydeWhite;
  background-color: $clydeBlack;
  border-radius: 50%;
  margin: auto 8px 0px;
}

.history-modal .history-modal__close {
  display: flex;
  position: static;
  justify-content: flex-end;
  top: 32px;
  right: 20px;
  z-index: 10;
  :first-child {
    display: none;
  }
}

.claim-history__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.claim-history__events {
  flex-wrap: nowrap;
  margin:16px 0px;
}

.pseudo-link {
  text-decoration: underline;
  cursor: pointer;
  margin: auto 16px;
}

.claim-history-modal__help {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 0;

  div {
    display: flex;
    flex-direction: row;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  p {
    margin-bottom: .5rem;
  }

  svg {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 8px;
  }
}

.claim-history__event__list {
  height: 100vh;
  border-radius: 32px 32px 0px 0px;
}

@include respond-to(md) {
  .history-modal .clyde-modal__content {
    padding: 10% 20%;
    background-color: transparent;
  }

  .claim-history__event__list {
    height: 100vh;
    border-radius: 8px 8px 8px 8px;
  }

  .claim-history__event__list {
    height: 100%;
  }
}
