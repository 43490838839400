.find-contract-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 0 0;
  padding:100px 0 0px 0px;
}

.find-contract-container__title {
  text-align: center;
}

// Written like this because it needs to override
.btn.find-contract-container__submit {
  border-radius: 0px;
  width: 200px;
  border: 0;
}

// Same as above
.form-inline {
  .form-control.find-contract-container__input {
    border-radius: 0;
    width: 240px;
  }
}

.form-inline {
  flex-direction: column !important;
}

// Same
.form-text.find-contract-container__help {
  margin: 20px 0 0 0;
  text-align: center;
  &.find-contract-container__help--error {
    color:#D52424 !important; // We have to use important because bootstrap decided they did
    svg {
      height: 17px;
      width: 17px;
    }
  }
}

.find-contract-container__top {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0 70px 0px;
}

.find-contract-container__sub-title{
  margin: 0 0 25px 0;
  text-align: center;
}

.find-contract-container__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundBlack;
  color: $clydeWhite;
  border-radius: 0 0 8px 8px;
  padding: 60px 40px;
  // padding: 55px 70px;
}.find-contract-container__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundBlack;
  color: $clydeWhite;
  border-radius: 0 0 8px 8px;
  padding: 60px 40px;
  // padding: 55px 70px;
}

.find-contract-container__bottom__left {
  display: flex;
  margin-bottom: 20px;
  svg {
    margin: 4px 15px 0px 0;
  }
  a, a:hover {
    text-decoration: underline;
    color: $clydeWhite;
  }
}

.find-contract-container__bottom__button {
  min-width: 200px;
}

.guest-contract-container {
  width: 100%;
}

.guest-contract-container__cta {
  margin: 70px 0 0 0;
  display: flex;
  justify-content: center;
  color: $clydeText;
  svg {
    margin: 4px 15px 0px 0;
  }
  a {
    text-decoration: underline;
    color: $clydeText;
  }
}

@include respond-to(md) {
  .find-contract-container__bottom {
    padding: 55px 70px;
  }
}

@include respond-to(lg) {
  .find-contract-container {
    width: 980px;
  }

  .find-contract-container {
    display: block;
  }

  .find-contract-container__top {
    width: 100%;
  }

  .form-inline {
    flex-direction: row !important;
  }

  .form-inline {
    .form-control.find-contract-container__input {
      width: 440px;
      margin: 0;
    }
  }

  .find-contract-container__bottom {
    flex-direction: row;
    justify-content: space-between;
  }

  .find-contract-container__bottom__left {
    margin-bottom: 0;
  }
}
