.claim-item {
  padding: 48px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  margin-bottom: 50px;
}

.claim-item__header,
.claim-item__body,
.claim-item__footer {
  display: flex;
  flex-direction: column;
}

.claim-item__footer {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.claim-item__body {
  justify-content: space-between;
  align-items: center;
}

.claim-item__title {
  line-height: 120%;
  margin: auto 0px;
}

.claim-item__info,
.claim-item__divider {
  color: $clydeInactiveText;
}

.claim-item__divider {
  margin: 0px 24px;
}

.claim-item__link {
  color: $clydeText;
  text-decoration: underline;
}

.claim-item__divider,
.claim-item__history {
  display: none;
}

.claim-item__history {
  margin: 0px;
}

.claim-item__title {
  padding-bottom: 24px;
  margin-bottom: 24px;
  margin-top: 8px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.3);
  width: 100%;
  text-align: center;
}

.claim-item__info {
  &:first-child {
    margin-bottom: 4px;
  }
}

.claim-item__button {
  width: 100%;
}

.pseudo-link.claim-item__history {
  margin-left: 0px;
}

@include respond-to(md) {
  .claim-item__button {
    width: auto;
  }

  .claim-item__divider,
  .claim-item__history {
    display: flex;
  }

  .claim-item__title {
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    border-bottom: none;
    width: default;
    text-align: left;
  }

  .claim-item__footer,
  .claim-item__body,
  .claim-item__header {
    flex-direction: row;
  }

  .claim-item__footer {
    border-top: 1px solid rgba(200, 200, 200, 0.3);
    padding-top: 24px;
    flex-direction: row;
    align-items: default;
  }
}
