@import './_claim-form/claim-documentation.scss';
@import './_claim-form/claim-issues.scss';

// MODAL STYLES

#claim-form.clyde-modal__background.active {
  background-color: rgba(0, 0, 0, 0.7);
}

.claim-modal h2 {
  display: none;
}

.claim-modal .clyde-modal__content {
  padding: 0;
}

.claim-modal .clyde-modal__close {
  justify-content: flex-end;
  top: 32px;
  right: 20px;
  z-index: 10;
  :first-child {
    display: none;
  }
}

.clyde-modal__higher-z-index {
  z-index: 10;
}

.claim-modal .clyde-modal__top {
  background: transparent;
}

.claim-modal .claim-form {
  .clyde--x-circle {
    display: flex;
  }
}

.claim-modal .claim-form--response {
  .clyde--x-circle {
    display: none;
  }
}

// TOP LEVEL FOR STYLES
.clyde-modal--hide-close {
  .clyde--x-circle {
    display: none;
  }
}

.claim-form {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  background: $backgroundWhite;
}

.claim-form {
  .svg-wrapper--clyde-black {
    color: $clydeBlack;
  }
}

.claim-form .btn.button--general--claim-submit{
  margin-top: 40px;
}

.claim-form ul {
  list-style: none;
  padding: 5px 0;
}

// REJECTION STATE STYLES
.claim-form--response {
  background: transparent;
  align-items: center;
  justify-content: center;
}

// CLAIM FORM NAV STYLES

.claim-form__nav {
  padding: 0 5%;
}

.claim-form__nav h3 {
  font-family: 'Volte';
}

.claim-form__nav__review-header {
  display: none;
}

.claim-form__nav__summary .claim-form__nav__step-list {
  display: none;
}

.claim-form__nav__step {
  margin: 15px 0;
  color: $claim-form-mid-grey;
}

.claim-form__nav__step.active,
.claim-form__nav__step.completed {
  color: black;
}

.claim-form__nav__step  h4 {
  font-size: 15px;
  margin: 0;
  font-family: 'Volte Medium';
  line-height: normal;
  color: inherit;
}

.claim-form__nav__step__header {
  display: flex;
  width: max-content;
  align-items: center;
  color: inherit;
  margin: 0;
  cursor: pointer;
}

.claim-form__nav__step__data {
  display: none;
  color: $claim-form-dk-grey-2;
  margin-left: 21px;
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.claim-form__nav__step .claim-form__step__title--margin-left {
  margin-top: 10px;
  margin-left: 35px;
}

.claim-form__nav__step__dot {
  display: flex;
  justify-content: center;
  align-items: center;;
  height: 12px;
  width: 12px;
  margin-right: 10px;
  margin-top: -2px;
  color: white;
  background-color: $claim-form-mid-grey;
  border-radius: 6px;
  flex-shrink: 0;
}

.claim-form__nav__step.completed .claim-form__nav__step__dot {
  background-color: $clydeBlack;
}

.claim-form__nav__step.active .claim-form__nav__step__dot {
  background-color: $clydeBlack;
}

.claim-form__step .claim-form__nav__step__data {
  display: block;
  margin-left: 35px;
}

.claim-form__step .completed .claim-form__nav__step__dot {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  position: relative;
}

.claim-form__nav__header {
  display: flex;

  flex-direction: column;
  width: 100%;
  padding: 15px 6px;
  padding-top: 15px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 0;
  background: white;
  position: fixed;
  left: 0;

  .header-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .header-bottom {
    display: flex;
  }
}

.claim-form__nav__header h2 {
  font-size: 24px;
  line-height: initial;
}

.claim-form__nav__header h3 {
  margin: 0;
  font-size: 17px;
  line-height: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.claim-form__nav__header__image {
  border-radius: 100%;
  height: 60px;
  width: 60px;
  background-size: auto 60px;
  background-position: center;
}

.claim-form__nav__header__product-info {
  margin-left: 10px;
  width: 60%;
}

.claim-form__nav__header__product-info p,
.claim-form__nav__header__product-info a {
  display: none;
}

.claim-form__nav__submit-label {
  margin-bottom: 5px;
  text-align: center;
}

.claim-form__nav__item-header__label {
  display: flex;
}

.claim-form__nav__item-header__label button {
  display: none;
  padding: 0;
  margin: 0 5px;
  color: $clydeLinkOrange;
  background: transparent;
  border: none;
  line-height: initial;
}

.claim-form__step .claim-form__nav__item-header__label button {
  display: block;
}


// CLAIM FORM DEFAULT STEP STYLES

.claim-form__step {
  padding: 0 5%;
  margin-top: 192px;
}

.claim-form__step--approved-step {
  text-align: left;
  min-width: 90%;
}

.claim-form__step--replacement__address-button,
.claim-form__step--check__address-button {
  height: 42px;
  background: $textWhite;
  color: $backgroundBlack;
  border: 1px solid $claim-form-mid-grey;
  border-radius: 4px;
  margin-right: 16px;
  margin-top: -12px;
  box-shadow: none;

  &:hover {
    background: $claim-form-lt-grey;
    color: $clydeText;
    border-color: $claim-form-mid-grey;
  }

  &:focus {
    box-shadow: none;
  }
}

.claim-form__step--replacement__address-form {
  max-height: 0;
  transition: .5s all;
  overflow: hidden;
}

.claim-form__step--replacement__address-form--expanded {
  max-height: 1000px;
}

.claim-form__step--replacement__address-button--selected,
.claim-form__step--check__address-button--selected {
  background: $claim-form-lt-grey;
}

.claim-form__step.claim-form__summary {
  margin-top: 110px;
}

.claim-form__replacement__address__row,
.claim-form__payout__address__row {
  display: flex;
  flex-direction: row;
  
  div {
    flex: 1;
    margin-left: 20px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  .replacement__address__row__cell--large {
    flex: 2;
  }
}

.claim-form__step__input-explain {
  display: block;
}

.claim-form__step__input-explain,
.claim-form__step__input-feedback {
  margin-top: -5px;
}

.claim-form__step__input-feedback {
  margin-bottom: 5px;
}

.claim-form__step .claim-form__step__next-button,
.claim-form__step .claim-form__step__payout-button {
  background: $clydeBlack;
  margin-top: 40px;
}

.claim-form__step__secondary-prompt {
  margin: 0px 0 12px;
}

.claim-form__form__additional-question-label {
  font-size: 16px !important;
  line-height: unset !important;
  cursor: pointer;

  input {
    cursor: pointer;
  }
}

// hate doing this but freakin libraries make me do it
div.radio-button-container__item.selected > div > label {
  color: $clydeBlack;
}

// CLAIM DESCRIPTION STYLES
.claim-form__form-title,
.claim-form__form-sub-title {
  margin-top: 0;
  margin-bottom: 12px;
}

.claim-form__form__characted-count {
  display: flex;
  margin-left: auto;
}

textarea.claim-form__form__description {
  height: 150px;
  margin-bottom: 10px;
}

.claim-form__form__description-error {
  border: 1px solid $inactiveRed;
}

.claim-form__form__description-valid {
  border: 1px solid $clydeGreen;
}

.claim-form__form_description-char-count-valid {
  color: $clydeGreen;
}

.claim-form__form_description-char-count-error {
  color: $inactiveRed;
}

.claim-form__contact-step__error {
  display: block;
  color: $inactiveRed;
  margin-top: -10px;
  margin-left: 8px;
}

// CLAIM DATE STYLES

.claim-form__date__input {
  font-size: 17px;
  text-transform: uppercase;
}

// CLAIM HANDOFF STYLES

.claim-form__response__container {
  background: $clydeWhite;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100% - 100px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.claim-form__response__body {
  display: flex;
  position: absolute;
  max-width: 1000px;
  min-height: 100%;
  top: 0;
  margin: 0;
  padding: 45px 20px;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.claim-modal .claim-form--response .claim-form__response__body {
  .clyde--x-circle {
    display: none;
    position: fixed;
    top: 15px;
    height: 32px;
    width: 32px;
    padding: 6px 0 0;
    font-size: 32px;
    font-family: 'Volte';
    justify-content: center;
    align-items: center;
    border-radius: 32px;
  }
}

.claim-modal.active .claim-form--response .claim-form__response__body .clyde--x-circle {
  display: flex;
}

.claim-form__response__body p {
  font-size: 18px;
  line-height: 28px;
}

.claim-form__handoff__next-steps {
  strong {
    font-family: 'Volte Medium';
  }
}

.claim-form__response__container .claim-form__response__out-button {
  position: absolute;
  bottom: 20px;
  right: 5%;
  width: 90%;
  margin: 0;
}

.claim-form__response__button {
  margin-top: 50px;
  padding: 0 50px;
  width: max-content;
}

.claim-form__response__link {
  margin: 0 auto 20px;
}

.claim-form__response__out-link {
  width: 100%;
}

.claim-form__response__container .claim-form__response__out-button {
  position: static;
  width: auto;
  margin: 16px 0 0;
  padding: 0 24px;
}

.claim-form__response__container .claim-form__response__out-button--subdued {
  position: relative;
  margin: 20px 0 0;
  padding: 0;
  color: black;
  background: none;
  border: none;
  text-decoration: underline;
}

.claim-form__response__limited-warranty .claim-form__response__out-button {
  right: 10%;
}

.claim-form__response__promo-code-buttons {  
  width: 100%;
  display: flex;
  max-width: 500px;
  flex-direction: column;
  justify-content: center;

  .claim-form__response__out-button {
    padding: 0 20px;
  }

  .button--click-copy {
    margin: 16px 0 0;
    height: 50px;
    width: auto;
  }
}

.claim-form__response__step-map {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
}

.claim-form__response__link {
  display: block;
  width: max-content;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  text-decoration: underline;
  color: $claim-form-dk-grey-2;
}

.claim-form__response__body__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  margin-bottom: 30px;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background: rgba(25, 95, 231, 0.1);
}

.claim-form__response__body__icon--underClydeReview {
  height: 85px;
  width: 85px;
}

.claim-form--response-approved {
  .claim-form__response__body__icon {
    background: none;
  }
}

.claim-form__response__body__icon--usage-question {
  background: none;
}

.claim-form__response__body .claim-form__response__info {
  margin-top: 20px;
  font-size: 24px;
  line-height: 36px;
}

.claim-form__response__button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $textWhite;
  height: 50px;
  padding: 0 25px;
  margin: 50px auto 7px;
  background-color: $clydeGreen;
  border-color: $clydeGreen;
  border-radius: 10px;
  font-size: 17px;

  &:hover {
    color: $textWhite;
    text-decoration: none;
  }
}

.claim-form__handoff__explanation {
  border: 1px solid $clydeBlack;
  border-radius: 6px;
  padding: 5px 14px;

  svg {
    display: none;
  }
}

.claim-form__response__orange-text {
  color: $clydeLinkOrange;
  border-color: $clydeLinkOrange;
}

.claim-form__response__step-map__connection {
  position: absolute;
  z-index: 1;
  top: 19px;
  width: 150px;
  right: 30px;
  border-top: 1px solid $clydeBlack;
  border-color: inherit;
}

.claim-form__response__step-map {
  display: flex;
}

.claim-form__response__step-map__step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-right: 65px;
  border-color: $clydeBlack;
  color: $clydeBlack;

  &:last-of-type {
    margin-right: 0;
  }
}

.claim-form__response__step-map__step__number {
  display: flex;
  height: 38px;
  width: 38px;
  padding-top: 3px;
  z-index: 2;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid $clydeBlack;
  border-color: inherit;
  color: inherit;
  font-family: 'Volte Semibold';
  font-size: 19px;
  margin-bottom: 7px;
  background-color: white;
}

.claim-form__response__step-map__step__name {
  color: inherit;
}

// resolution map states

.claim-form__response__step-map__step--pending {
  color: #C5C5C5;
  border-color: #C5C5C5;
}

.claim-form__response__step-map__step--completed {
  .claim-form__response__step-map__step__number {
    background: $clydeBlack;
    color: white;
  }
}

// CLAIM SUCCESS STYLES

.claim-form-success__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  width: 100%;
  max-width: 1000px;
  padding: 20px 0;

  button, a {
    pointer-events: auto;
  }
}

.claim-form-success {
  width: 80%;
  margin: 30px auto;
  padding: 30px;
  background: white;
  border-radius: 10px;
}

.claim-form-success h2 {
  display: block;
  font-family: 'Volte';
  text-align: center;
}

.claim-form-success h4 {
  color: black;
  font-family: 'Volte';
}

.claim-form-success .claim-form-success__button {
  width: 50%;
  margin-left: 25%;
}

.claim-form-success__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: -20px 0 0;
}

.claim-form-success__block__svg {
  width: 70%;
  color: $clydeText;
}

.claim-form-success__block__svg--approved {
  width: 70%;
  color: $clydeGreen;
}

.claim-form-success__block__svg--underClydeReview {
  width: 85px;
  height: 85px;
}

.claim-form-success--promo-code {
  p, h4  {
    margin-bottom: 25px;
  }

  .claims-form-success__button {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0 30px;
    width: auto;
  }

  .claim-form-success__block {
    h4 {
      margin-bottom: 0;
    }
  }

  .claim-form-success__block__svg {
    width: auto;
    margin-top: 24px;
    flex-shrink: 0;

    svg {
      height: 70px;
      width: auto;
    }
  }
}

// CONFIRMATION CHECK STYLES

.claim-form__replacement__check,
.claim-form__payout__check,
.claim-form__nav__confirmation__check {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px 0;
}

.claim-form__nav__confirmation__check {
  justify-content: left;
}

.claim-form__replacement__check,
.claim-form__payout__check {
  justify-content: flex-start;
  label {
    margin: 0 30px;
  }
}

.claim-form__replacement__check__input,
.claim-form__payout__check__input,
.claim-form__nav__confirmation__check__input {
  outline: 0;
  display: none;
  -webkit-appearance: none;
  height: 24px;
  min-width: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 20px;
  color: #FFF;
  background: #FBFBFB;
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  margin-top: -2px;
  margin-right: 10px;
  padding: 2px 6px;
  position: relative;
  cursor: pointer;
  transition: all 200ms ease;
}

.claim-form__replacement__check__input:focus,
.claim-form__replacement__check__input:hover,
.claim-form__payout__check__input:focus,
.claim-form__payout__check__input:hover,
.claim-form__nav__confirmation__check__input:focus,
.claim-form__nav__confirmation__check__input:hover {
  box-shadow: 0 4px 7px 0 rgba(0,0,0,.2);
}

.claim-form__replacement__check__input:checked,
.claim-form__payout__check__input:checked,
.claim-form__nav__confirmation__check__input:checked {
  background: $clydeBlack;
  border: none;
}

.claim-form__replacement__check__input:checked:after,
.claim-form__payout__check__input:checked:after,
.claim-form__nav__confirmation__check__input:checked:after {
  content: '✔';
  font-size: 16px;
  color: #ffffff;
  position: absolute;
  top: 2px;
  left: 6px;
  cursor: pointer;
}

// resolution form

.claim-history__event__list__resolution-button {
  margin: 10px 0;
  padding: 10px;
  color: $clydeText;
  border: 2px solid $clydeText;
  border-radius: 10px;
  background: $backgroundWhite;
}

.claim-form__step__resolution-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -10px;
}

.claim-form__step__payout-select {
  display: flex;
  justify-content: space-between;
}

.claim-form__step__payout-select__tile {
  display: flex;
  height: 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $buttonBorderGrey;
  border-radius: 10px;
  flex: 1;
  margin-right: 20px;
  cursor: pointer;

  h4, p {
    text-align: center;
    margin: 0;
  }

  p {
    font-size: 11px;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.claim-form__step__payout-select__tile--selected {
  background: $claim-form-lt-grey;
}

.claim-form__step__payout-info,
.claim-form__step__promo-info {
  margin: 30px 0 0;
}

.claim-form__step .claim-form__step__payout-button,
.claim-form__step .claim-form__step__promo-button {
  margin-top: 0;
}

.claim-form__step .claim-form__step__promo-button {
  margin: 0;
}

.claim-form__step__resolution__fake-button {
  color: $clydeWhite;
  border-radius: 10px;
}

.claim-form-resolution {
  justify-content: center;
}

.claim-form-resolution .claim-form__nav {
  display: none;
}

.claim-form__loading-bars {
  text-align: center;
  svg {
    width: 75px;
    fill: $clydeText;
  }
}

// Mobile Progress Bar
.claim-form-progress-bar {
  display: flex;
  align-items: center;
  padding: 21px 16px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.11);
  width: 100%;

  &__circle-container {
    width: 30px;
    height: 30px;
  }

  &__text {
    font-size: 11px;
    line-height: 13px;
    color: $clydeText;
    margin-left: 8px;
  }

  &__navigation {
    display: flex;
    margin-left: auto;
    
    &--back,
    &--forward {
      border: none;
      background: none;
    }
  }
}

.claim-form__nav__step-list-container {
  margin-top: 110px;
}

// Since progress bar is fixed and inside modal, need to counter margin-top not working in
// review page due to header and nav being in same div.
.top-0 {
  top: 0;
}


.radio-button-container {
  display: flex;
  width: 100%;
  margin-top: 16px;

  &__item {
    display: flex;
    border: 1px solid #EEEEEE;
    color: #727272;
    border-radius: 4px;
    width: 100%;
    max-width: 183px;
    height: 56px;
    accent-color: black;

    &:not(:first-child) {
      margin-left: 8px;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 100%;

      span {
        margin-left: 14px;
      }

      input[type="radio"] {
        position: static;
        margin-left: 2px;
        margin-bottom: 5px;
      }
    }
  }

  &__item.selected {
    border: 1px solid $clydeBlack;
    color: $clydeText !important;
  }
}

// DESKTOP ADJUSTMENTS

@include respond-to(sm) {
  .claim-form__nav__summary .claim-form__nav__step-list {
    display: initial;
    font-size: 15px;
    border-top: 1px solid $claim-form-lt-grey;
  }

  .claim-form-success__block {
    flex-direction: row;
  }

  .claim-form__step--replacement__address-form--expanded {
    max-height: 700px;
  }

  .claim-form__response__container {
    top: 95px;
    padding: 20px 0 100px;
    background: transparent;
    bottom: auto;
  }

  .claim-form__response__body {
    position: relative;
    height: 100%;
    min-height: auto;
    margin: 0 5%;
    padding: 80px 60px;
  }

  .claim-form__response__body__icon {
    margin-top: 70px;
  }

  .claim-form__response__container .claim-form__response__out-button {
    width: 100%;
    margin: 32px 0 90px;
  }

  .claim-form__handoff__explanation {
    border: 0;
    padding: 0;

    svg {
      display: inline;
    }
  }
  .claim-form__response__promo-code-buttons {
    flex-direction: row;

    .button--click-copy {
      margin: 32px 20px 90px 0;
    }
  }

  .claim-form__response__container .claim-form__response__out-button {
    margin: 32px 0 0;
  }

  .claim-form__response__container .claim-form__response__out-button--subdued {
    margin: -70px 0 90px;
  }

  .claim-form__response__step-map {
    // margin-top: -60px;
    margin-bottom: 30px;
  }

  .claim-modal .claim-form--response .claim-form__response__body {
    .clyde--x-circle {
      position: absolute;
      top: 20px;
      right: 20px;
      height: 20px;
      width: 20px;
      max-width: 20px;
      padding-top: 3px;
      font-family: 'Volte Semibold';
      font-size: 19px;
      padding: 3px 0 0;
    }
  }

  // claim form nav
  .claim-form-resolution .claim-form__nav {
    display: block;
  }

  .claim-form__nav__header {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    padding: 0;
    position: relative;
    
    .header-top {
      justify-content: flex-start;
      flex-direction: column;
      align-items: start;
    }

    .header-bottom {
      display: none;
    }
  }

  // Mobile Progress Bar
  .claim-form-progress-bar {
    display: none;
  }

  .claim-form__step,
  .claim-form__nav__step-list-container,
  .claim-form__step.claim-form__summary {
    margin-top: 0px;
  }
}

@include respond-to(md) {
  .claim-form-resolution {
    justify-content: baseline;
  }

  .claim-form__step__resolution-info {
    margin-left: -25px;
    margin-top: -30px;
  }

  .claim-form {
    padding: 0 5%;
  }

  .claim-form-success {
    width: 520px;
  }

  .claim-form-success__block {
    flex-direction: row;
  }

  .claim-form-success__block__svg {
    width: 100%;
  }

  // Mobile Progress Bar
  .claim-form-progress-bar {
    display: none;
  }
}

@include respond-to(lg) {
  .claim-modal .clyde-modal__close {
    justify-content: flex-end;
    top: 32px;
    right: 20px;
  }

  .claim-form {
    width: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 15%;
  }

  .claim-form--response {
    padding-left: 0;
  }

  .claim-form__step { 
    padding: 0;
    margin-top: 50px;
    width: 100%;
  }

  .claim-form__questions,
  .claim-form__contact-info {
    width: 590px;
  }

  .claim-form__type,
  .claim-form__parts {
    padding-right: 5%;
  }

  .claim-form__description {
    width: 505px;
  }

  .claim-form__documentation {
    padding-right: 15%;
  }

  .claim-form__failure-date {
    width: 371px;
  }

  .claim-form__summary {
    width: 611px;
  }

  .claim-form__step h2 {
    display: block;
    margin-bottom: 50px;
    padding-bottom: 30px;
    font-size: 40px;
    font-family: Volte;
    font-weight: normal;
    border-bottom: 1px solid $claim-form-lt-grey;
  }

  .claim-form__nav {
    max-width: 290px;
    margin-top: 50px;
    margin-left: 0;
    padding: 0;
  }

  .claim-form-spacer {
    flex: 1;
  }

  .claim-form__nav__item-header__label button {
    display: block;
  }

  .claim-form__nav__step-list {
    border-top: 0;
  }

  .claim-form__nav__step {
    border-top: 0;

  }

  .claim-form__nav__step__data {
    display: block;
  }

  .claim-form__nav__header {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    padding: 0;
    position: relative;
    
    .header-top {
      justify-content: flex-start;
      flex-direction: column;
      align-items: start;
    }

    .header-bottom {
      display: none;
    }
  }

  .claim-form__nav__header h3 {
    font-family: 'Volte Medium';
    text-overflow: initial;
    overflow: initial;
    white-space: initial;
  }

  .claim-form__nav__header__product-info {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
  }

  .claim-form__nav__header__product-info h2 {
    display: none;
  }

  .claim-form__nav__header__product-info p {
    display: block;
    margin: 5px 0;
    font-size: 15px;
  }

  .claim-form__nav__header__product-info a {
    display: block;
    font-size: 15px;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
  }

  .claim-form__step .claim-form__nav__header {
    justify-content: flex-start;
    width: 25%;
  }

  .claim-form__step .claim-form__nav__summary {
    width: 50%;
  }

  .claim-form__nav__review-header {
    display: block;

    h2 {
      display: block;
      margin-bottom: 50px;
      padding-bottom: 30px;
      font-size: 40px;
      font-family: Volte;
      font-weight: normal;
      border-bottom: 1px solid $claim-form-lt-grey;
    }
  }

  .claim-form__response__container {
    position: relative;
    flex-direction: column;
    justify-content: center;
    min-height: auto;
    top: 0;
    width: 100%;
    padding: 20px 0;
  }

  .claim-form__response__body {
    width: 80%;
    height: auto;
    min-height: auto;
    margin: 0 10%;
    padding: 32px 60px 80px;
    flex: 1;
  }

  .claim-form__response__body h2 {
    position: absolute;
    display: flex;
    padding: 0;
    margin: 0;
    top: -125px;
    left: 10%;
    right: 0;
    align-items: center;
    height: 125px;
    z-index: 5;
    background: white;
    font-size: 40px;
    font-family: Volte;
    font-weight: normal;
  }

  .claim-form__response__step-map {
    display: flex;
    margin-bottom: 70px;
  }

  .claim-form__step--replacement__address-form--expanded {
    max-height: 600px;
  }

  // Mobile Progress Bar
  .claim-form-progress-bar {
    display: none;
  }

  .claim-form__nav__step-list-container {
    margin-top: 0px;
  }

  .claim-form__nav__summary {
    padding-right: 24px;
  }
}
