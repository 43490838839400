.settings-tile {
  margin: auto;
  background: #FFFFFF;
  width: 80%;
}
.settings-tile-container {
  margin-bottom: 40px
}
.settings-tile__title {
  font-weight: bold;
  font-size: 22px;
  border: 2px solid #D7D7D7;
  border-radius: 5px 5px 0 0;
  margin-bottom: -2px;
  padding: 15px;
  letter-spacing: -0.46px;
}
.settings-tile__content {
  padding: 15px;
  border: 2px solid #D7D7D7;
  border-radius: 0 0 5px 5px;
  min-height: 150px;
}
.settings-tile__content__loading-container {
  position: relative;
  top: 40px;
}