.account-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 480px);
  width: 100%;
  margin: 20px 0 0 0;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.profile-form-container,
.account-password-form-container {
  padding: 30px;
  flex-grow: 1;
}

.profile-form-container {
  max-height: 500px;
}

.account-password-form-container {
  max-height: 400px;
}

.profile-form-container__title,
.account-password-form-container__title {
  margin: 0 0 20px;
}

.profile-form-container__form__button,
.account-password-form-container__form__button{
  width: 40%;
  margin: 20px 0 0 0;
}

.account-password-form-container__form__button--success {
  pointer-events: none;
}

.account-navigation-container{
  display: flex;
  flex-direction: row;
  height: 60px;
  margin-bottom: 30px;
}

.account-navigation-container__nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px 20px 20px;
  width: 100%;
  color: $clydeText;
  svg {
    display: none;
  }
  &:hover{
    text-decoration: none;
  }
  &.active {
    font-family: $semiBoldFontFamily;
    font-weight: 600;
    svg {
      display: block;
      transform: rotate(90deg);
    }
  }
}

.account-navigation-container__arrow {
  display: none;
}

.account-navigation-container__nav-item + .account-navigation-container__nav-item {
  border-left: 1px solid #eee;
}

@include respond-to(md) {
  .form-container {
    flex-direction: row;
  }

  .profile-form-container,
  .account-password-form-container {
    margin-left: 40px;
  }

  .account-navigation-container{
    flex-direction: column;
    align-self: baseline;
    height: auto;
    width: 270px;
    margin-bottom: 0;
  }

  .account-navigation-container__nav-item {
    justify-content: space-between;
    height: 74px;
    &.active {
      svg {
        transform: none;
      }
    }
  }

  .account-navigation-container__nav-item + .account-navigation-container__nav-item {
    border-left: none;
    border-top: 1px solid #eee;
  }

  .account-navigation-container__arrow {
    display: block;
  }
}

@include respond-to(lg) {
  .account-container {
    width: 100%;
    justify-content: center;
  }

  .profile-form-container,
  .account-password-form-container {
    max-width: 650px;
  }
}

@include respond-to(xl) {
  .account-container {
    padding: 0 189px;
  }
}
