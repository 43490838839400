@font-face {
  font-family: 'Volte Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Regular Italic'), url('../fonts/Volte Regular Italic.woff') format('woff');
}
@font-face {
  font-family: 'Volte';
  font-style: normal;
  font-weight: normal;
  src: local('Volte'), url('../fonts/Volte.woff') format('woff');
}
@font-face {
  font-family: 'Volte Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Light Italic'), url('../fonts/Volte Light Italic.woff') format('woff');
}
@font-face {
  font-family: 'Volte Light';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Light'), url('../fonts/Volte Light.woff') format('woff');
}
@font-face {
  font-family: 'Volte Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Medium Italic'), url('../fonts/Volte Medium Italic.woff') format('woff');
}
@font-face {
  font-family: 'Volte Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Medium'), url('../fonts/Volte Medium.woff') format('woff');
}
@font-face {
  font-family: 'Volte Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Semibold Italic'), url('../fonts/Volte Semibold Italic.woff') format('woff');
}
@font-face {
  font-family: 'Volte Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Semibold'), url('../fonts/Volte Semibold.woff') format('woff');
}
@font-face {
  font-family: 'Volte Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Bold Italic'), url('../fonts/Volte Bold Italic.woff') format('woff');
}
@font-face {
  font-family: 'Volte Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Bold'), url('../fonts/Volte Bold.woff') format('woff');
}

h1, h2, h3, h4, h5, h6 {
  font-family: $boldFontFamily;
}

h2 {
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

h3 {
  font-size: 24px;
  line-height: 34px;
}

h4 {
  font-family: "Volte Light";
  color: #666666;
  font-size: 24px;
  line-height: 34px;
  &.h4--black{
    color: $clydeText;
  }
}

p, span, a, div, section {
  font-family: $standardFontFamily;
}

.h2--white {
  color: white;
}

.h2--center {
  text-align: center;
}

.clyde-muted-text {
  font-family: $standardFontFamily;
  font-size: 15px;
  line-height: 21px;
  color: $clydeMutedGrey;
}

.clyde--warning {
  font-family: 'Volte';
  font-size: 16px;
  line-height: 21px;
  color: $clydeAlert;
  text-overflow: ellipsis;
  width: 200px;
  overflow: hidden;
  height: 21px;
  text-align: center;
}

.clyde--bold-small {
  font-family: 'Volte SemiBold';
  font-size: 15px;
  line-height: 21px;
}

.clyde--x-circle {
  font-family: 'Volte SemiBold';
  color: $textWhite;
  border: none;
  border-radius: 30px;
  background-color: $backgroundBlack;
  height: 20px;
  font-size:19px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 3px;
  padding-left: 6px;
  cursor: pointer;
  &:hover{
    background: $clydeMediumGrey;
  }
}

.clyde--muted-text {
  color: rgba(92, 92, 92, 0.6);
  font-size: 15px;
}
