// CLAIM DOCUMENTATION STYLES
.claim-form__documentation__receipt__upload,
.claim-form__documentation__upload {
  display: flex;
  width: 100%;
  padding: 20px;
  background: none;
  border: 1px solid $claim-form-mid-grey;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.claim-form__documentation__receipt__upload {
  border: 2px solid $clydeMidGrey;
  border-radius: 8px;
  justify-content: center;

  svg {
    margin-right: 16px;
  }
}

.claim-form__documentation__receipt__upload__input-text,
.claim-form__documentation__upload__input-text {
  display: block;
  width: min-content;
  margin: auto;
  color: $clydeLinkOrange;
}

.claim-form__documentation__receipt__upload__input-text {
  width: auto;
  color: $clydeBlack;
}

.claim-form__documentation__upload__input {
  display: none;
}

.input-disabled {
  cursor: not-allowed;
}

.claim-form__documentation__prompt-text span {
  color: $claim-form-dk-grey-2;
}

.claim-form__documentation--hidden-mobile {
  display: none;
  &:first-of-type {
    display: flex;
  }
}

.claim-form__documentation__bypass-warning,
.claim-form__documentation__file-warning {
  color: $claim-form-red;
}

.claim-form__documentation__bypass-warning {
  display: block;
  margin-top: 10px;
  text-align: center;
}

.claim-form__documentation__receipt {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid $clydeMidGrey;
}

// DESKTOP ADJUSTMENTS
@include respond-to(lg) {
  .claim-form__documentation__upload-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .claim-form__documentation__upload {
    display: flex;
    flex-direction: column;
    height: 135px;
    width: 240px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .claim-form__documentation__upload:not(:last-of-type) {
    margin-right: 16px;
  }

  .claim-form__documentation--hidden-mobile {
    display: flex;
  }

  .claim-form__documentation__prompt-text {
    margin-bottom: 16px;
    text-align: center;
  }
}
