html {
  height: 100%;
}

body {
  height: 100%;
  background-color: $backgroundGrey;
  font-family: '$standardFontFamily';
  color: $clydeText;
}

#root {
  background-color: $backgroundGrey;
}

a {
  color: $clydeLinkOrange;
}

a:hover {
  color: $clydeLinkOrangeHover;
}

a, a:hover {
  text-decoration: none;
}
[type=text],
[type=password],
[type=number] {
  width: 100%;
  padding: 6px 10px;
  border-style: none;
  border-radius: 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  height: 38px;
  border: 1px solid $buttonBorderGrey;
}
[type=text]:focus,
[type=password]:focus,
[type=number]:focus {
  outline: 0;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #BBB;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #BBB;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #BBB;
}

.text-align--center {
  text-align: center;
}

.link-general {
  font-family: $standardFontFamily;
  font-size: 15px;
  line-height: 21px;
}

.clyde-link {
  color: $clydeText;
  text-decoration: underline;
  &:hover {
    color: $clydeMediumGrey;
    text-decoration: underline;
  }
}

.clyde-link-secondary {
  @extend .clyde-link;
  color: $clydeLinkOrange;
  &:hover {
    color: $clydeLinkOrangeHover;
  }
}


.fake-link {
  @extend .link-general;
  color: $clydeText;
  cursor: pointer;
}

.fake-link--alt {
  font-weight: bold;
  display: inline;
}

.btn.button--general {
  background: $backgroundBlack;
  color: $clydeWhite;
  border-radius: 8px;
  width: 100%;
  font-family: $standardFontFamily;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  height: 50px;
  border: 0;
  svg {
    height: 28px;
  }
  &:hover {
    background: $buttonHoverDarkGrey;
  }
  &:disabled {
    background-color: $inactiveGrey;
  }
}

.btn.button--inverted {
  background-color: $backgroundWhite;
  color: $clydeText;
  border-radius: 10px;
  width: 100%;
  font-family: $standardFontFamily;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  height: 50px;
  max-width: 200px;
  border: 0;
}

.btn.button--disabled {
  background-color: $inactiveGrey;
  color: $clydeWhite;
  border-radius: 10px;
  width: 100%;
  font-family: $standardFontFamily;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  height: 50px;
  max-width: 200px;
  border: 0;
}

.btn.button--click-copy {
  height: auto;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $clydeText;
  background: $clydeWhite;
  border: 1px solid $buttonBorderGrey;
  border-radius: 5px;

  div {
    margin: 0;
  }

  .button--click-copy__prefix {
    color: inherit;
  }

  .button--click-copy__input__wrapper {
    display: inline-block;
    width: auto;
    margin-right: 16px;
  }

  .button--click-copy__input {
    border: 0;
    margin: 0;
    padding: 0;
    font-size: inherit;
    background: inherit;
    outline: none;
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  .button--click-copy__tip {
    color: $clydeText;
  }
}

.link--disabled {
  pointer-events: none;
}

.form-group label {
  font-family: $standardFontFamily;
  font-size: 15px;
  line-height: 18px;
  color: $clydeBlack;
  margin-bottom: 0px;
}

//Universal bullet styles
.bullets {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 15px 0;
}

.bullets__bullet {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.bullet__checkmark,
.back-button__back-arrow {
  display: inline-block;
  margin: 0px 7px 4px 0;
  color: $clydeText;
  fill: $clydeText;
}

.bullet__checkmark {
  min-width: 12px;
}

.bullet__text {
  display: inline-block;
}

.clyde-shadow {
  background: $clydeWhite;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.clyde-big-shadow {
  background: $clydeWhite;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.clyde--spacer {
  color: #E0E0E0;
}

// Bootstrap over-ride
input.form-control {
  font-size: 15px;
  height: 50px;
  padding: 10px;
}

.clyde-form-feedback {
  font-size: 16px;
}

.clyde-form-feedback--success {
  color: $clydeText;
}

.clyde-form-feedback--spacing {
  margin: 20px 0 0 0;
}

.clyde-form-feedback--error {
  color: $claim-form-red;
}

.clyde-flash {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  line-height: 50px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  top: -50px;
  opacity: 0;
}

.clyde-flash--pro {
  background-color: $backgroundGrey;
  color: $backgroundWhite;
}

.clyde-flash--con {
  background-color: $clydeMagenta;
  color: $backgroundWhite;
}

.clyde-flash-enter {
  opacity: 0;
  top: -50px;
}
.clyde-flash-enter-active {
  opacity: 1;
  top: 0px;
  transition: all 300ms;
}
.clyde-flash-exit {
  opacity: 1;
  top: 0px;
}
.clyde-flash-exit-active {
  opacity: 0;
  top: -50px;
  transition: all 300ms;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Chrome and Opera */
}
.claim-history-status {
  font-family: Volte Semibold;
}

.claim-history-status--resolutionInitiated,
.claim-history-status--approved,
.claim-history-status--resolutionInProgress,
.claim-history-status--needMoreInfo,
.claim-history-status--hiClydeHandoff,
.claim-history-status--underClydeReview,
.claim-history-status--underAdminReview,
.claim-history-status--resolutionFulfilled,
.claim-history-status--customerWithdrawn,
.claim-history-status--inProcess {
  color: $clydeText;
}

.claim-history-status--denied {
  color: $textGrey;
}

.claim__font--inProcess {
  color: $clydeText;
}
.claim__font--hiClydeHandoff,
.claim__font--underClydeReview,
.claim__font--underAdminReview {
  color: $clydePurple;
}
.claim__font--needMoreInfo {
  color: $claim-form-red;
}
.claim__font--approved {
  color: $clydeGreen;
}
.claim__font--customerWithdrawn,
.claim__font--denied {
  color: $textGrey;
}

.claim__font--resolutionInProgress {
  color: lighten($clydeText, 20%);
}
.claim__font--resolutionFulfilled {
  color: $clydeText;
}

.claim__bg--inProcess {
  background-color: $backgroundGrey;
}
.claim__bg--hiClydeHandoff,
.claim__bg--underClydeReview,
.claim__bg--underAdminReview {
  background-color: $clydePurple;
}
.claim__bg--needMoreInfo {
  background-color: #ff4500;
}
.claim__bg--approved {
  background-color: $clydeGreen;
}
.claim__bg--customerWithdrawn,
.claim__bg--denied {
  background-color: $textGrey;
}


.claim__bg--resolutionInProgress {
  background-color: lighten($clydeText, 20%);
}
.claim__bg--resolutionFulfilled {
  background-color: $clydeText;
}

.button-no-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-rendering: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-shadow: inherit;
  text-align: inherit;
  align-items: inherit;
}
