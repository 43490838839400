.claim-details {
  padding: 32px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  margin-bottom: 50px;
}

.claim-details__primary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.claim-details__header,
.claim-details__body,
.claim-details__footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.claim-details__footer {
  margin-top: 24px;
}

.claim-details__body {
  justify-content: space-between;
  align-items: center;
}

.claim-details__title {
  line-height: 120%;
  margin: auto 0px;
  width: 100%;
}

.claim-details__info,
.claim-details__divider {
  color: $clydeInactiveText;
}

.claim-details__divider {
  margin: 0px 24px;
}

.claim-details__left {
  width: 100%;
};

.product-details {
  display: none;
  flex-direction: column;
  min-width: 380px;
}

.claim-summary__title {
  font-family: 'Volte Semibold';
  margin-bottom: .5rem;
}

.claim-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}

.claim-summary__right,
.claim-summary__left {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
}

.product-details__header {
  background: $backgroundBlack;
  color: $clydeWhite;
  text-align: center;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 0px;
  height: 24px;
  font-family: 'Volte Semibold';
  line-height: 240%;
  font-size: 11px;
}


.product-details__body {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #D1D1D1;
  border-top-width: 0px;
  display: flex;
  flex-direction: row;
}

.product-details__image {
  width: 128px;
  height: 128px;
  border-radius: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 24px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.product-details__title {
  margin-top: 36px;
}

.product-details__info {
  margin-top: 8px
}

.product-details__text {
  margin: 4px 0px 0px 0px;
}

.product-details__text {
  color: $clydeInactiveText;
}

.cdp-top {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  align-items: center;
  min-height: 60px;
  flex-wrap: wrap;
  color: #000000;
}

.claim-details__divider {
  display: none;
}

.breadcrumb-arrow {
  margin: 0px 16px;
}

.breadcrumb-current {
  line-height: 21px;
  margin-bottom: 0px;
  font-family: 'volte semibold'
}

.pseudo-link {
  margin-left: 0px;
  margin-top: 24px;
}

.pseudo-link:only-child {
  margin-left: 0px;
  margin-top: 0px;
}

.cdp-back-link {
  justify-content: space-around;
  color: $clydeBlack;
}

.claim-summary__detail {
  p {
    word-wrap: break-word;
  }
}

.claim-summary__contact {
  margin-bottom: 0px;
}

.pdp-link {
  display: flex;
  text-decoration: underline;
  justify-content: left;
  margin-top: 16px;
  color: $clydeBlack;
}

@include respond-to(md) {
  .product-details {
    display: flex;
  }

  .pdp-link {
    display: none;
  }

  .claim-details__left,
  .product-details {
    width: 50%;
  }

  .pseudo-link {
    margin-left: 24px;
    margin-top: auto;
  }

  .pseudo-link:only-child {
    margin-left: 0px;
    margin-top: 0px;
  }

  .claim-details__divider {
    display: flex;
  }

  .claim-details {
    padding: 48px;
  }

  .claim-details__header,
  .claim-details__body,
  .claim-details__footer,
  .claim-summary {
    flex-direction: row;
  }

  .claim-summary__right,
  .claim-summary__left {
    max-width: 50%;
  }
}
