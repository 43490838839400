.file-claim-handoff {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.file-claim-handoff__modal {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  background: #fff;
  margin: auto;
  padding: 40px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.1);
  overflow: auto;

  .button--general {
    display: block;
    width: auto;
    margin: 0 auto;
    padding: 0 24px;
  }
}

.file-claim-handoff__modal__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: -30px;
  text-wrap: balance;

  p {
    font-family: 'Volte Medium';
    margin: 0;
  }
}

.file-claim-handoff__modal__cards {
  display: flex;
  width: calc(100% + 40px);
  flex-shrink: 0;
  flex-direction: row;
  overflow-x: scroll;
  justify-content: space-between;
  margin: 0 0 30px -20px;
  padding: 5px 50px;
  
  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.file-claim-handoff__modal__cards__card {
  flex-shrink: 0;
  width: 250px;
  height: 220px;
  padding: 20px;
  border-radius: 5px;
  background-color: $clydeWhite;
  box-shadow: 0 4px 10px 0px rgba(0,0,0,.1);
  margin: 0 25px 20px 0;
}

.file-claim-handoff__modal__cards__spacer {
  flex-shrink: 0;
  width: 25px;
}

.file-claim-handoff__modal__cards__card__image {
  margin-bottom: 20px;
  svg {
    height: 40px;
    color: $clydeText;
  }
}

.file-claim-handoff__modal__cards__card__title {
  margin-bottom: 5px;
  h5 {
    font-size: 18px;
    margin-bottom: 0;
  }
}

.file-claim-handoff__modal__cards__card__text {
  font-size: 14px;
}

.file-claim-handoff__modal__card-indicator {
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  justify-content: center;
}

.file-claim-handoff__modal__card-indicator__dot {
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  transition: all .1s;
  border: 2px solid transparent;

  &:last-of-type {
    margin-right: 0;
  }
}

.file-claim-handoff__modal__card-indicator__dot__inner {
  height: 6px;
  width: 6px;
  background: $clydeMidGrey;
  border-radius: 6px;
  transition: inherit;
}

.file-claim-handoff__modal__card-indicator__dot--active {
  border: 2px solid $clydeText;
  margin-right: 11px;

  .file-claim-handoff__modal__card-indicator__dot__inner {
    background: $clydeText;
  }
}

@include respond-to(sm) {

}

@include respond-to(md) {
  .file-claim-handoff__modal {
    height: 75%;
    width: 75%;
    padding: 40px 60px;

    .button--general {
      width: 100%;
    }
  }

  .file-claim-handoff__modal__cards {
    display: flex;
    width: calc(100% + 120px);
    margin: 0 0 0 -60px;
  }

  .file-claim-handoff__modal__cards__card {
    margin: 0 25px 10px 0;
  }

  .file-claim-handoff__modal__card-indicator {
    margin-bottom: 16px;
  }
  

  .file-claim-handoff__modal__cards__card {
    width: 320px;
    height: 220px;
  }
}

@include respond-to(lg) {
  .file-claim-handoff__modal__cards__card {
    width: 200px;
    height: 230px;
  }

  .file-claim-handoff__modal__cards {
    align-items: center;
    width: 100%;
    overflow-x: initial;
    padding: 0;
    margin: 0 0 30px;
  }

  .file-claim-handoff__modal__cards__card {
    flex-shrink: 1;
    margin: 0 0 25px;
  }

  .file-claim-handoff__modal__cards__spacer {
    display: none;
  }

  .file-claim-handoff__modal__card-indicator {
    display: none;
  }
}

@include respond-to(xl) {
  .file-claim-handoff__modal__cards__card {
    width: 250px;
    height: 220px;
  }
}
