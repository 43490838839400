.clyde-modal--claim-link {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -100vh;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  transition: bottom 300ms ease;
  &.active {
    bottom: 0;
    transition: bottom 300ms ease;
  }

  .clyde-modal__bottom {
    display: none;
  }

  .clyde-modal__top {
    position: relative;
    top: auto;
    bottom: auto;
    min-width: 750px;
    width: 75%;
    height: auto;
    border-radius: 10px;
  }

  .button--general {
    width: 80%;
    margin: 0 10% 70px;
  }
}

.claim-link-modal__logos {
  width: 100%;
  text-align: center;

  svg, img {
    height: 30px;
    width: auto;
    margin: 10px 25px 0;
  }
}

.claim-link-modal__header {
  width: 75%;
  margin: 50px auto 40px;
  text-align: center; 
}

.claim-link-modal__deck {
  width: 80%;
  margin: 0 auto 50px;
  display: flex;
}

.claim-link-modal__deck__card {
  margin: 0;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex: 1;

  &:nth-of-type(2) {
    margin: 0 20px;
  }

  svg {
    height: 35px;
  }

  h5 {
    margin-top: 40px;
    font-family: 'Volte';
    font-size: 17px;
    line-height: 27px;
  }

  p {
    font-size: 13px;
  }
}
