.co-branding {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-content: center;
  margin-block: 20px 40px;

  &__left,
  &__right {
    align-self: center;
  }

  &__center {
    display: none;
  }

  &__right,
  &--single {
    img {
      height: 22px;
    }
  }

  &__left img {
    max-height: 60px;
    max-width: 147px;
  }

  @include respond-to(sm) {
    &:not(&--single) {
      padding-block: 8px;
      display: grid;
      grid-template-columns: 1fr 2px 1fr;
      align-items: center;
      margin-bottom: 40px;
      gap: 16px;
    }

    &__center {
      display: initial;
      height: 25px;
      border: 1px solid #d8d8d8;
    }

    &__left {
      justify-self: flex-end;
    }

    &__left img {
      max-width: none;
    }
  }

  @include respond-to(md) {
    & {
      margin-bottom: 50px;
      max-width: 90%;
      gap: 25px;
    }
  }

  @include respond-to(lg) {
    & {
      margin-bottom: 60px;
    }
  }
}
