//Colors
$clydeBlue: #1A5EE5;
$clydeInactiveText: #ccc;
$clydeActiveText: #8024E4;
$clydePurple: #321798;
$clydeAltBlue: #196FE7;
$clydeLightestBlue: #E8F0FD;
$clydeBlueMuted: #5E8FEE;
$clydeGreen: #05c379;
$sortOfaCreamyClydeWhite: #FAFAFA;
$textGrey : #909090;
// $backgroundGrey: #F3F3F3;
$buttonBorderGrey: #d9d9d9;
$buttonHoverGrey: #d7d7d7;
$buttonHoverDarkGrey: #414141;
$backgroundBlue:#1462eb29;
$inactiveRed: #F15A57;
$mutedYellow: #FDEC4E;
$clydeMutedGrey: #b1b1b1;
$clydeMidGrey: #D1D1D1;
// $clydeMediumGrey: #727272;
$claim-form-dk-grey: #6d7278;
$claim-form-dk-grey-2: #777777;
$claim-form-mid-grey: #d8d8d8;
$claim-form-lt-grey: #F6F6F6;
$claim-form-red: #FF5656;

//Font family
$standardFontFamily: 'Volte';
$boldFontFamily: 'Volte Medium';
$semiBoldFontFamily: 'Volte Semibold';

//Colors
$clydeOrange: #FF8400;
$clydeMagenta: #DF91F7;
$clydeGrey: #F6F6F6;
$clydeLightestGrey: #EEEEEE;
$clydeMediumGrey: #949494;
$clydeGradient: linear-gradient(90deg, #FEED7A 0%, #FF8400 49.87%, #DF91FF 100%);
$inactiveGrey: #D1D1D1;
$clydeWhite: #FFFFFF;
$clydeBlack: #000000;

// fonts
$textWhite: #FFFFFF;
$clydeText: #000000;
$clydeAlert: #D52424;
$clydeLinkOrange: #E07400;
$clydeLinkOrangeHover: #F69C3B;

// background colors
$backgroundGrey: #F6F6F4; 
$backgroundGreyIneligible: #F4F4F4; 
$backgroundBlack: #000000;
$backgroundWhite: #FFFFFF;
$backgroundBlackGradient: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(38, 38, 38, 0.6);

// box shadows
$howItWorksBoxShadow: 0px 8.86702px 9.45815px rgba(0, 0, 0, 0.1),
inset 0px 0.636826px 3.82096px rgba(255, 255, 255, 0.25);

// Media query helpers
$break-xs: 350px;
$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;

@mixin respond-to($media) {
  @if $media == xs {
    @media only screen and (min-width: $break-xs) { @content; }
  }
  @if $media == sm {
    @media only screen and (min-width: $break-sm) { @content; }
  }
  @else if $media == md {
    @media only screen and (min-width: $break-md) { @content; }
  }
  @else if $media == lg {
    @media only screen and (min-width: $break-lg) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: $break-xl) { @content; }
  }
}
