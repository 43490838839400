.checkout-container {
  min-height: calc(100vh - 460px);
}

.checkout-container__checkout {
  display: flex;
  flex-direction: column;
}

.checkout-container__checkout__nothing {
  height: 200px;
}

.checkout__left {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.checkout__left__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 0px 0px;
  padding: 0 10px 0 10px;
  h2 {
    text-align: center;
  }
}

.checkout__right {
  display: flex;
  justify-content: center;
  height: 30%;
}

// Cart
.cart {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0px;
}

.cart__header {
  align-self: flex-start;
}

.cart__sum-table {
  width: 270px;
  float: right;
  margin: 25px;
}

.cart__sum-table__row {
  font-size: 17px;
  line-height: 27px;
} 

.cart__sum-table__row--left {
  text-align: left;
}

.cart__sum-table__row--right {
  text-align: right;
}

.cart__sum-table__row--bold {
  font-family: 'Volte SemiBold';
  font-size: 24px;
  line-height: 34px;
  padding: 10px 0 0 0;
}

// Cart items
.cart-items {
  margin: 14px 0 0 0;
}

.cart-item {
  width: 300px;
  height: 245px;
  margin: 0 0 20px 0;
  padding: 20px;
}

.cart-item__top {
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.cart-item__top__img {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  background-color: white;
  margin: 0 10px 0 0;
}

.cart-item__top__product-info {
  min-width: 100px;
  max-width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    font-size: 12px;
  }
}

.cart-item__top__cta.clyde--warning {
  width: 80px;
  height: auto;
  font-size: 12px;
  text-align: end;
  margin: 0 0 0 auto;
}

.cart-item-header {
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
}

.cart-item__bottom {
  display: flex;
  flex-wrap: wrap;
  // grid-template-columns: auto auto;
  // text-align: center;
  // flex-direction: column;
}

.cart-item__bottom__input {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cart-item__bottom__input__quantity,
.cart-item__bottom__output__tax {
  width: 75px;
  display: flex;
  flex-direction: column;
}

.cart-item__bottom__input__warranty,
.cart-item__bottom__output__total {
  width: 175px;
  display: flex;
  flex-direction: column;
}

.cart-item-content {
  font-size: 14px;
}

.cart-item__bottom__output {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@mixin select-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 10px) calc(1em + 2px);
  background-size:
    5px 5px,
    5px 5px;
  background-repeat: no-repeat;
}

.contract-quantity.form-control,
.contract-selector.form-control {
  @include select-arrow;
  font-size: 14px;
}

//Checkout form
.checkout-form {
  margin-top: 20px;
  width: 300px;
  padding: 35px 30px;
}

.checkout-form__feedback {
  font-size: 14px;
  text-align: center;
  margin-top: 27px;
}

.stripe-form {
  border: 1px solid #ced4da;
  padding: 17px 0 0 8px;
  border-radius: 4px;
  border-radius: 4px;
  height: 50px;
}

.checkout-success-modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(212, 212, 212, 0.56);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;

  svg {
    margin-bottom: 32px;
  }

  .button--general {
    width: auto;
    padding: 12px 24px;
  }

  .clyde-modal__close {
    width: auto;
    margin-right: 8px;

    .clyde--x-circle {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 6px 0 0;
      font-size: 32px;
      font-family: 'Volte';
      justify-content: center;
      align-items: center;
      border-radius: 32px;
    }
  }
}

.checkout-success-modal__content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px 20px 60px 20px;
}

.checkout-success-modal__content__left {
  margin: 0 20px;
}

.checkout-success-modal__content__left__img{
  max-height: 230px;
}

.clyde--x-circle--checkout {
  font-family: 'Volte';
  position: absolute;
  margin: -24px 0 0 264px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  font-size: 34px;
  padding-left: 7px;
  padding-top: 5px;
  padding-right: 0px;
}

@include respond-to(sm) {
  .checkout__left {
    width: auto;
  }

  .cart {
    width: 450px;
    display: block;
  }

  .cart-item {
    width: 450px;
    height: 250px;
  }

  .cart-item__top__product-info {
    max-width: 200px;
  }

  .cart-item__top__cta.clyde--warning {
    margin-left: auto;
  }

  .cart-item__bottom__input,
  .cart-item__bottom__output {
    justify-content: flex-start;
  }

  .cart-item__bottom__input__quantity,
  .cart-item__bottom__output__tax {
    width: 110px;
  }

  .cart-item__bottom__input__warranty,
  .cart-item__bottom__output__total {
    width: 300px;
  }

  .cart-item-content {
    font-size: 16px;
  }

  .contract-quantity.form-control,
  .contract-selector.form-control {
    width: 75%;
    font-size: 16px;
  }

  .clyde--x-circle--checkout {
    margin: -29px 0 0 419px;
  }

  .checkout-form {
    width: 320px;
  }

  .checkout-success-modal__content {
    width: 60%;
    height: 600px;

    .clyde-modal__close {
      display: none;
    }
  }
}

@include respond-to(md) {
  .checkout__left__header {
    margin: 0 0 0 0;
  }

  .cart {
    width: 590px;
    padding: 45px 0 0 0;
  }

  .cart-item {
    width: 590px;
    height: 195px;
  }

  .cart-item__top {
    margin-bottom: 15px;
  }

  .cart-item__top__product-info {
    span {
      font-size: inherit;
    }
    a {
      font-size: 14px;
    }
  }

  .cart-item__top__product-info {
    max-width: 300px;
  }

  .cart-item__top__cta.clyde--warning {
    width: fit-content;
    font-size: inherit;
    margin-right: 0px;
  }

  .cart-item__bottom__input {
    width: 70%;
  }

  .cart-item__bottom__input__quantity{
    width: 30%;
  }

  .cart-item__bottom__input__warranty {
    width: 70%;
  }

  .cart-item__bottom__output {
    width: 30%;
  }

  .cart-item__bottom__output__tax,
  .cart-item__bottom__output__total {
    width: 50%;
  }

  .cart-item-content {
    line-height: 38px;
  }

  .clyde--x-circle--checkout {
    margin: -30px 0 0 559px;
  }

  .checkout-form {
    width: 420px;
  }
}

@include respond-to(lg) {
  .cart {
    padding-top: 10px;
  }

  .checkout-form {
    width: 520px;
  }

  .checkout-success-modal__content{
    width: 60%;
    height: 410px;
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 35px 60px 20px;
  }
}

// Checkout needs a custom media query because it is wider than most pages
@media only screen and (min-width: 1100px) {
  .checkout-container__checkout {
    flex-direction: row;
    align-items: center;
  }

  .checkout__left {
    display: block;
    margin-bottom: 0;
    margin-right: -18px;
  }

  .checkout__right {
    margin-top: 60px;
    margin-left: -18px;
  }

  .checkout__left__header {
    display: block;
    padding: 0;
    h2 {
      text-align: left;
    }
  }

  .checkout-form {
    width: 320px;
    margin: 0 0 0 60px;
  }
}
