.auth-main {
  display: flex;
  flex-direction: column;
}

.auth-main__logo {
  width: 104px;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-right: auto;
}

.auth-main__left {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  min-height: 100vh;
}

.auth-main__left--padded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0% 32px;
}

.auth-main__right {
  color: $textWhite;
  overflow: hidden;
  height: 1000px;
  background: $clydeBlack;
}

.auth-form-container {
  color: $clydeText;

  .form-control {
    height: 50px;
    background: $backgroundWhite;
    box-sizing: border-box;
    border: 1px solid $clydeMidGrey;
    border-radius: 10px;

    &::placeholder {
      color: $clydeMidGrey;
    }
  }

  .form-control.is-invalid {
    border: 1px solid $claim-form-red;
  }

  Input[type="checkbox"] {
    margin: 0;
    height: 16px;
    width: 16px;
  }
}

.auth-form-container--password-reset {
  min-height: 370px;
}

.auth-form-container__title {
  order: 0;
  flex-grow: 0;
  font-weight: 600;
  font-size: 18px;
  margin: 24px 0px;
}

.auth-form-container__success-link {
  font-size: 12px;
}

.auth-form-container__title--new-pw {
  margin-bottom: 20px;

  svg {
    width: 36px;
    margin-bottom: 4px;
    margin-right: 10px;
  }
}

.auth-form-container__copy {
  margin: 0;
}

.auth-form-container__form {
  margin: 20px 0;
}

.auth-form-container__form__button {
  height: 50px;
  margin-bottom: 24px;
}

.auth-form-container__form__google-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background: $backgroundWhite;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
}

.auth-form-container__form__google-button__google-icon {
  background: url('/images/google-logo.png') transparent 50% no-repeat;
  width: 50px;
  height: 50px;
}

.auth-form-container__form__divider {
  display: flex;
  text-align: center;
  color: $claim-form-dk-grey;
  width: 100%;
  overflow: hidden;

  span::before,
  span::after {
    background-color: $clydeMidGrey;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  span:before {
    right: 16px;
    margin-left: -50%;
  }

  span:after {
    left: 16px;
    margin-right: -50%;
  }
}

.button--general.auth-form-container__form__button--success {
  pointer-events: none;
}

.auth-form-container__subsection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 0px;

  Label {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;

    color: $clydeText;
    margin-bottom: 8px;
  }
}

.auth-form-container__subsection--checkbox {
  display: flex;
  flex-direction: column;
  margin: 1px;
  justify-content: center;
}

.auth-form-container__subsection--single-line {
  flex-direction: row;
}

.auth-form-container__subsection--text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: $clydeText;
}

.auth-form-container__subsection--remember-me {
  padding-left: 28px;
}

.auth-form-container__subsection--link {
  text-decoration-line: underline;

  strong {
    font-style: normal;
    font-weight: 1200;
  }
}

.auth-form-container__subsection--login-text {
  font-size: 16px;
}

.auth-form-container__subsection--change-pw {
  justify-content: center;
  margin-bottom: 50px;
}

.auth-form-container__not-a-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.auth-form-container__already-a-user {
  display: flex !important;
  justify-content: center;
}

.auth-main__footer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-bottom: 50px;
}

.auth-main__footer__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  text-decoration-line: underline;
  color: $claim-form-dk-grey;
  margin: 0 10px;
}

.how-section {
  width: 100%;
  overflow: visible;
  margin-top: 50px;
}

/** How container **/
.how-section__title {
  margin: 0 0 15px 40px;
  text-align: left;
  max-width: 70%;
  size: 36px;
  font-family: Volte;
  font-weight: 600;
  padding-left: 1%;
}

.how-section__text {
  margin: 0 0 30px 40px;
  text-align: left;
  size: 16px;
  font-family: Volte;
  font-weight: 400;
  line-height: 24px;
  padding-right: 6%;
  padding-left: 1.5%;
}

.how-bullets {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow: visible !important;
}

.brandon-glow {
  position: static;
  z-index: 3;
  width: 100%;
  height: 15%;
  bottom: 0%;
  right: 0%;
}

.how-bullet-item {
  width: 87%;
  margin: 0 0 20px 20px;
  display: flex;
  background: $backgroundBlackGradient;
  box-shadow: $howItWorksBoxShadow;
  border-radius: 20px;
  color: $textWhite;
  align-items: center;
}

.how-bullet-item__icon {
  margin: 20px 0 0 0;
  padding: 0;

  svg {
    height: 122px;
    width: 122px;
    color: $clydeBlack;
  }
}

.how-bullet-item__text {
  margin: 10px 0 0 0;
  padding: 10px;
  padding-left: 0px;

  h4 {
    margin-bottom: 5px;
    font-family: 'Volte Bold';
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $textWhite;
  }

  p {
    font-family: 'Volte';
    font-size: 15px;
    line-height: 21px;
    margin: 0;
    font-weight: 400;
  }
}

.logout-container {
  min-height: 80vh;
}

.unsub-container {
  min-height: 50vh;
  padding: 20px;
}

@include respond-to(xs) {
  .brandon-glow {
    margin-top: auto;
  }
}

@include respond-to(sm) {
  .auth-main__footer__link {
    font-size: 14px;
  }
}

@include respond-to(md) {
  .auth-main {
    flex-direction: row;
  }

  .auth-main__left--padded {
    padding: 0% 10%;
  }

  .auth-form-container__subsection--login-text {
    font-size: 15px;
  }

  .auth-main__footer__link {
    font-size: 15px;
  }

  .auth-main__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    min-height: 100vh;
  }

  .auth-form-container__not-a-user {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: auto;
  }

  .auth-form-container__already-a-user {
    display: inherit !important;
    justify-content: inherit;
  }

  .auth-form-container__subsection--change-pw {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .brandon-glow {
    margin-top: -400px;
  }
}

@include respond-to(lg) {

  .auth-main__logo {
    margin-top: 0;
    margin-bottom: 73px;
  }

  .auth-form-container__title {
    font-size: 30px;
  }

  .auth-main__left--padded {
    padding: 0% 15%;
  }

  .auth-form-container__subsection {
    margin-bottom: 24px;
  }

  .auth-form-container__subsection--text {
    font-size: 16px;
  }

  .auth-main__footer {
    width: 100%;
    justify-content: flex-start;
    padding-left: 14%;

    :first-child {
      margin-left: 0;
    }
  }
}

@include respond-to(xl) {
  .auth-main__left--padded {
    padding: 0 202px;
  }

  @media screen and (min-height: 770px) {
    .auth-main__left {
      height: 100vh;
    }

    .auth-main__right {
      height: 100vh;
    }
  }


  .auth-main__footer {
    padding-left: 202px;
  }

  .auth-main__logo {
    margin-bottom: 3%;
  }
}
