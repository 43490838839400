.pdp-receipt-upload {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 18px 6px;
  color: $clydeText;
  border-radius: 8px;
  cursor: pointer;
  transition: all .5s ease;
  min-width: 500px;
  width: 100%;

  svg {
    margin: 0 18px 0 0;

    &:first-of-type {
      margin: 0 18px;
    }
  }
}

.pdp-receipt-upload__background,
.pdp-receipt-upload__background--progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $clydeLightestBlue;
  z-index: -1;
  border-radius: 8px;
}

.pdp-receipt-upload:hover .pdp-receipt-upload__background {
  background-color: #DBE9FF;
}

.pdp-receipt-upload__background--progress {
  background-color: darken($clydeLightestBlue, 10);
  width: 0%;
}

.pdp-receipt-upload__background--progress {
  &--processing {
    transition: all 1.8s ease;
    width: 90%;
  }
  &--finished {
    transition: all .2s ease;
    width: 100%;
  }
  &--error {
    transition: all 1.8s ease;
    width: 0%;
  }
}

.pdp-receipt-upload__input {
  display: none;
}

.pdp-receipt-upload__label {
  margin: 0 34px 0 0;
  cursor: pointer;
}

// errored styles
.pdp-receipt-upload--errored {
  color: $inactiveRed;

  .pdp-receipt-upload__background,
  .pdp-receipt-upload__background--progress {
    background-color: lighten($inactiveRed, 30);
  }
}

.pdp-receipt-upload--errored:hover .pdp-receipt-upload__background {
  background-color: lighten($inactiveRed, 30);
}

@include respond-to(lg) {
  .pdp-receipt-upload {
    width: auto;
    margin-top: 0px;

    &--finished {
      min-width: 150px;
    }
  }
}
