@import './_product/product-contract-history.scss';
@import './_product/receipt-upload.scss';

.pdp-container__product-history-label{
  margin-bottom: 20px;
}

.purchase-list-container {
  width: 100%;
  min-height: calc(100vh - 460px);
}

.pdp-top {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  flex-wrap: wrap;
  color: $clydeText;
}

.purchase-list-title {
  margin-left: 10px;
  margin-bottom: 10px;
}

.purchase-list {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  margin: 20px 0 0 0;
  // This is the title of the purchase list.
  :first-child {
    grid-column: 1 / -1;
  }

  // purchase-list needs custom media queries because it is wider than most elements
  @media only screen and (min-width: 844px) {
    grid-template-columns: auto auto;
  }
  @media only screen and (min-width: 1160px) {
    grid-template-columns: auto auto auto;
  }
  @media only screen and (min-width: 1700px) {
    grid-template-columns: auto auto auto auto;
  }

  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

.purchase-list-loading-dots-container {
  margin-top: 15vh;
  align-self: center;
}

.products-loading-text {
  size: 16px;
  margin-bottom: 25px;
  margin-top: -5px;
}

.purchase-list-item {
  position: relative;
  padding: 15px;
  margin: 0 10px 25px 10px;
  width: 310px;
  height: 419px;
  display: flex;
  flex-direction: column;
  background: $clydeWhite;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &.ineligible,
  &.expired {
    background: $backgroundGreyIneligible;
    border: 1px solid $inactiveGrey;
  }

  &__top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    position: absolute;
    right: 20px;
    top: 46px;
    width: 180px;
    max-height: 150px;
    padding: 12px 16px;
    background: $clydeWhite;
    border: 1px solid #D1D1D1;
    border-radius: 6px;
    margin: 0;
    list-style-type: none;
    display: none;
    color: #000000;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 180px;
      height: 50px;

      &:hover {
        cursor: pointer;
      }

      a {
        color: #000000;
      }

    }
  }

  &__menu.active {
    display: block;
  }
}

.dot-menu-active {
  cursor: pointer;
}

.dot-menu-disabled {
  cursor: not-allowed;
}

.purchase-list-item__status, .pdp-detail__status {
  font-family: 'Volte Semibold';
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 10px;
  &.eligible{
    color: $clydeOrange;
  }
  &.active {
    color: $clydeText
  }

  &.expired,
  &.ineligible{
    color: $clydeInactiveText;
  }
}

.pdp-detail__status {
  margin: 0 0 10px;
}

.purchase-list-item__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: inherit;
  &.eligible {
    height: inherit;
  }

  &.active,
  &.expired {
    height: 280px;
  }
}

.purchase-list-item__expires-text {
  margin-bottom: 20px;
}

.purchase-list-item__img{
  width:160px;
  height: 160px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 80px;
  background-color: $backgroundBlack;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;

  &.ineligible,
  &.expired {
    opacity: .6;
  }
}

.purchase-list-item__name {
  margin: 0 0 5px 0;
  font-family: $standardFontFamily;
  color: $clydeText;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
}

.purchase-list-item__manufacturer {
  font-size: 14px;
  color: #9A9A9A;
}

.purchase-list-item__cta-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.resolution-alert-modal__info .purchase-list__promo-code,
.purchase-list-item__cta-wrapper .purchase-list__promo-code {
  margin: 10px auto;
  color: $clydeText;
  border: 2px solid $clydeText;
  border-radius: 10px;

  .button--click-copy__copy-text {
    font-family: Volte Semibold;
  }

  input {
    color: $clydeText;
  }
}

.resolution-alert-modal__info .purchase-list__promo-code {
  width: auto;
}

.purchase-list-item__ctas {
  display: flex;
  justify-content: space-around;
  width: 100%;
  a {
    color: $clydeText;
    text-decoration: underline;
  }
}

.purchase-list-item__button{
  width: 100%;
}

// Purchase/Product Details

.pdp-container, .product-history-blank {
  width: 100%;
}

.pdp-back-link {
  display: flex;
  color: $clydeText;
  justify-content: space-between;
  width: 165px;
  span + span {
    margin: 1px 0 0 0;
  }
  &:hover{
    color: $clydeMediumGrey;
  }
}

.pdp-detail {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  margin: 10px 0 50px;
}

.pdp-detail__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.pdp-detail__right {
  flex-grow: .8;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pdp-detail__img {
  width: 30vw;
  height: 30vw;
  max-width: 300px;
  max-height: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15vw;
  background-color: $backgroundBlack;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.pdp-detail__details__info-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pdp-detail__details__info-secondary-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: fit-content;
}

.pdp-detail__manufacturer {
  color: #9A9A9A;
}

.pdp-detail__term {
  font-size: 15px;
}

.pdp-detail__details {
  border-bottom: 1px solid rgba(200,200,200,.3);
  padding-bottom: 15px;
}

.pdp-detail__details div {
  margin-bottom: 10px;
}

.pdp-detail__details div:last-child {
  margin-bottom: 0;
}

.pdp-detail__ctas {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdp-detail__ctas__terms {
  margin: 20px 0 0 0;
}

.pdp-detail__ctas__sign-up {
  margin-top: 10px;
}

.btn.pdp-detail__ctas__button {
  width: 205px;
}

.btn.pdp-detail__cta__button--view-terms {
  width: 205px;
  background: $backgroundWhite;
  border: 1px solid $clydeText;
  color: $clydeText;
  &:hover {
    background: $clydeLightestGrey;
  }
}

.btn.pdp-detail__ctas__button--expired {
  background: $clydeInactiveText;
  pointer-events: none;
}

// transfer banner
.pdp-transfer-contract {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 22px;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  margin-top: 32px;

  &:hover {
    cursor: pointer;
  }
}

.svg-container-right {
  margin-left: auto;
}

.svg-container-middle {
  margin-left: 16px;
}

.svg-container-left,
.svg-container-right {
  width: 24px;
  height: 24px;

  svg {
    height: 20px;
    width: 20px;
  }
}
// Modal changes
.contract-transfer-modal {
  .clyde-modal__top {
    width: 100%;
    margin: 0;
    height: 94%;
    bottom: 0px;
    border-radius: 8px;
  }

  .clyde-modal__content {
    padding: 0;
  }

  .clyde-modal__bottom {
    display: none;
  }
}

// Modal Content
.contract-transfer {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px 24px;

  &-header {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &__close {
      display: flex;
      width: 100%;
      height: 32px;
      margin-bottom: 24px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        background-color: #EEEEEE;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        font-size: 32px;
        font-family: 'Volte';
        cursor: pointer;
        margin-left: auto;
      }
    }

    &__image {
      margin-bottom: 24px;
    }

    &__copy {
      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        color: $clydeText;
        margin-bottom: 8px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $clydeText;
        margin: 0;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
  }

  &-form {
    display: flex;
    flex-direction: column;
  }

  &-review {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    background: #F6F6F4;
    border-radius: 8px;

    &__header,
    &__info {
      display: flex;
    }

    &__header {
      justify-content: space-between;
      margin-bottom: 16px;

      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
      }

      svg {
        cursor: pointer;
      }
    }

    &__info {
      flex-direction: column;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
  }

  &-btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    height: 50px;
    background: $clydeText;
    color: $clydeInactiveText;
    border-radius: 8px;
  }

  &-btn-secondary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    color: #000000;
    border-radius: 8px;
    margin-top: 16px;
  }

  .top-input-fields {
    display: flex;
    flex-direction: column;

    .contract-transfer-form-input {
      position: relative;
      display: flex;
      flex-direction: column;
      align-content: baseline;
      margin-bottom: 24px;
      width: 100%;
    }
  }

  .bottom-input-fields {
    display: flex;
    flex-direction: column;
    width: 100%;

    .contract-transfer-form-input {
      position: relative;
      display: flex;
      flex-direction: column;
      align-content: baseline;
      margin-bottom: 24px;
      width: 100%;
    }
  }
}

// resets
.icon-transfer {
  width: 75px;
  height: 75px;
}

.icon-transfer-edit {
  width: 24px;
  height: 24px;
}

.review-divider {
  height: 1px;
  width: 100%;
  background-color: $inactiveGrey;
  margin-bottom: 16px;
  margin-top: 0;
}

.transfer-error-text {
  font-size: 13px;
  line-height: 21px;
  color: $clydeAlert;
}

.transfer-error-field {
  border: 1px solid $clydeAlert !important;
}

.m-b-16 {
  margin-bottom: 16px;
}

.transfer-select {
  margin-bottom: 8px !important;
}

.transfer-input {
  margin-bottom: 0;

  .clyde-select-single__options-list--open {
    max-height: 150px;
    overflow-y: scroll;
  }
}

.transfer-mb-xl {
  margin-bottom: 467px;
}

.mb-0 {
  margin-bottom: 0;
}

.claim-btn-container {
  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    flex-direction: column;
  }

  &__top {
    align-items: center;
  }

  &__bottom {
    margin-top: 16px;
    font-size: 13px;
    line-height: 20px;
    color: $clydeText;
    margin-bottom: 16px;
    text-align: center;
  }

  a {
    color: $clydeText;
    text-decoration: underline;
  }
}

.transfer-name {
  font-size: 16px;
  display: inline;
  font-weight: bold;
}

.pdp-btn-as-link {
  all: unset;
  &:hover {
    all: unset;
    color: $clydeMediumGrey;
    text-decoration: underline;
  }
  text-decoration: underline;
}

// Purchase/Product History

.pdp-history {
  padding: 50px;
  margin: 10px 0 10px;
}

.product-history-blank {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
}

.product-history-blank__internal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.product-history-blank__icon-container {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background: $backgroundGrey;
  padding: 25px;
  margin-bottom: 35px;
}

.claim-link--mobile {
  display: block;
}

.claim-link--desktop {
  display: none;
}

/**
*
* No Products
*
**/

.no-products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  min-width: 365px;
  max-width: 1000px;
}

.not-covered {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: $backgroundWhite;
}

.not-covered__icon {
  height: 53px;
  width: 51px;
}

.info-section {
  margin-top: 24px;
  margin-bottom: 56px;
  max-width: 398px;
}

.info-section__title {
  font-family: $boldFontFamily;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.info-section__text {
  margin-top: 16px;
  margin-bottom: 0;
  font-family: Volte;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
}

.partner-logos-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: $backgroundWhite;
  border-radius: 10px;
  padding: 40px;
  max-width: 1000px;
}

.partner-logos-section__info-text {
  font-family: $boldFontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 160%;
  max-width: 45%;
  text-align: left;
  color: $clydeText;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 15px;
}

.partner-logos-section__logos-container {
  display: flex;
  flex-grow: .7;
  max-width: 50%;
}

.partner-logos-section__logos-container--inner {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  :last-child {
    margin-top: 7.5px;
  }
  :first-child {
    margin-left: -15px;
  }
}

.partner-logos-individual {
  height: 40px;
  margin: 0px 10px 0px 10px;
  flex-grow: 1;
  flex-basis: .25;
  display: flex;
  justify-content: flex-end;
  /* This is to make existing logos black */
  -webkit-filter: brightness(0) grayscale(200%); /* Safari 6.0 - 9.0 */
  filter: brightness(0) grayscale(100%);
}

@include respond-to(xs) {
  // No Products
  .partner-logos-section {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 40px 0px 40px 0px;
    height: 50%;
    width: 100%;
  }

  .partner-logos-section__info-text {
    max-width: 75%;
    text-align: center;
  }

  .partner-logos-section__logos-container{
    justify-content: center;
    align-content: center;
    max-width: 100%;
    width: 100%;
  }

  .partner-logos-section__logos-container--inner {
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 200px;
    align-items: center;
    align-content: space-around;
    margin: 0px;
    margin-left: 30px;
  }

  .partner-logos-individual {
    margin-top: 10px;
    margin-right: 35px;
  }
}



@include respond-to(sm) {
  .purchase-list-item {
    width: 320px;
  }

  .pdp-detail {
    padding: 40px;
  }

  .pdp-detail__ctas {
    flex-direction: row;
  }

  .pdp-detail__ctas__terms {
    margin: 0 0 0 50px;
  }

  .claim-btn-container {
    text-align: start;

    &__top {
      flex-direction: row;
    }

    &__bottom {
      margin-top: 16px;
      font-size: 16px;
      line-height: 20px;
      text-align: start;
    }
  }
}

@include respond-to(md) {
  .pdp-detail {
    padding: 30px;
  }

  .pdp-detail__details div {
    margin-bottom: 0;
  }

  .pdp-detail__details__info-main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .product-history-blank {
    flex-direction: row;
    padding: 70px 100px;
  }

  .claim-link--mobile {
    display: none;
  }

  .claim-link--desktop {
    display: block;
  }

  // transfer contract
  .contract-transfer-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .clyde-modal__top {
      max-width: 1000px;
      width: 100%;
      height: auto;
      bottom: initial;
    }

    .clyde-modal__content {
       padding: 0;
    }
  }

  .contract-transfer {
    padding: 24px 24px 48px 48px;

    &-header {
      margin-top: 0;

      &__image {
        margin-bottom: 24px;
      }

      &__copy {
        h2 {
          font-weight: 600;
          font-size: 24px;
          line-height: 38px;
          color: $clydeText;
          margin-bottom: 8px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: $clydeText;
          margin: 0;
        }
      }
    }

    &-body {
      flex-direction: row;
    }

    .top-input-fields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .contract-transfer-form-input {
        position: relative;
        display: flex;
        flex-direction: column;
        align-content: baseline;
        margin-bottom: 16px;
        width: calc((100% / 2) - 8px);

        &:nth-child(odd) {
          margin-right: 16px;
        }
      }
    }

    .bottom-input-fields {
      display: flex;
      flex-direction: row;
      width: 100%;

      .contract-transfer-form-input {
        position: relative;
        display: flex;
        flex-direction: column;
        align-content: baseline;
        margin-bottom: 16px;
        width: calc(100% - 32px);

        &:nth-child(2) {
          margin-left: 16px;
          margin-right: 16px;
        }
      }
    }
  }

  // contranct transfer resets
  .transfer-input {
    margin-bottom: 8px !important;

    .clyde-select-single__options-list--open {
      max-height: 150px;
      overflow-y: scroll;
    }
  }

  .transfer-mb-xl {
    margin-bottom: 56px;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }
}

@include respond-to(lg) {
  .pdp-detail {
    flex-direction: row;
    padding: 50px;
  }

  .pdp-detail__left {
    margin-bottom: 0;
  }

  .pdp-detail__right {
    margin: 0 0 0 20px;
  }

  .pdp-detail__term {
    margin-left: 10px;
  }

  // No Products
  .partner-logos-section {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-radius: 10px;
    padding: 40px 35px 40px 35px;
    max-width: 1000px;
  }

  .partner-logos-section__info-text {
    text-align: left;
    margin-right: 20px;
    margin: 0px;
    margin-top: 7px;
  }

  .partner-logos-section__logos-container{
    max-width: 50%;
    width: auto;
    margin-top: -10px;
    padding-top: 0px;
    padding-left: 40px;
    justify-content: flex-end;
  }

  .partner-logos-section__logos-container--inner {
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    margin-left: auto;
    flex-basis: 1;
  }

  .partner-logos-individual {
    margin-top: 10px;
    margin-right: 30px;
  }
}

@include respond-to(xl) {
  .pdp-detail__right {
    margin: 0 0 0 40px;
  }

  .pdp-detail__details__info-secondary-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
